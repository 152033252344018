import React from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { Link } from 'react-router-dom';
import { CoverageStatusType, EntityLabelEnum, Equipment, OrganizationFeatureFlagEnum, patchEquipment } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { Avatar } from '@mui/material';
import { Translation } from 'react-i18next';
import { arrayToGrammaticalList } from '../../common/functions/arrayToGrammaticalList';
import Urls from '../../routes/Urls';
import { AuthContextValue, AuthorizationData } from '../../common/auth/AuthContext';
import AddressColumn from '../../common/components/Address/AddressColumn';
import { getEquipmentNotes } from '../../graphql/queries/equipmentGraphQLQueries';
import { rolesThatCanViewCoverageData } from '../coverages/rolesThatCanViewCoverageData';
import statesAsObject from '../../common/constants/statesAsObject';
import { toESTDateStringFromUnixMillisecondTimestamp } from '../../common/functions/expensiveDateFunctions';
import {
  allowedNoteParentLabels,
  clientOrganizationTypes,
  enumObjectToSelectOptions,
  equipmentStatusType,
  equipmentHealthType,
  getEnumDisplayName,
  getEnumValue,
  equipsCoveragePmCadenceType,
  monthsToPmEquipmentType,
  organizationIndustryTypes,
  coverageStatuses,
} from '../../graphql/enums';
import { centsToMoney, convertDailyPriceToAnnualized } from '../../common/functions/moneyFunctions';
import NestedArrayPopover from '../../common/components/NestedArrayPopover';
import { TableColumn } from '../../common/components/DataTable';
import { clientValueForFilter, providerValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import {
  standardEntityOptionsSerializerAndDeserializer,
  standardNoteSerializerAndDeserializer,
} from '../../common/functions/standardSerializersAndDeserializers';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import TableCellNotes from '../../common/components/DataTable/TableCellNotes';
import TableCellCopyData from '../../common/components/DataTable/TableCellCopyData';
import { customers, internalUsers } from '../../common/auth/roles';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import { dateRangeOptions } from '../../common/components/Date/DateRangeFilter';
import TableCellExpand from '../../common/components/DataTable/TableCellExpand';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import ClientTag from '../../common/components/Tags/ClientTag';
import TierIndicator from '../organizations/components/TierIndicator';
import { abbreviateName } from '../../common/functions/abbreviateName';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../common/functions/phoneFunctions';
import EquipmentCoverageCell, { equipmentCoverageText } from './components/EquipmentCoverageCell';
import { agreementFilterTypeOptions } from './equipmentScreenQuery';
import { EquipmentRowActions } from './components/EquipmentRowActions';

export function getEquipmentColumns(
  userCan: AuthContextValue['userCan'],
  auth: AuthorizationData | null | undefined,
  determineFeatureFlagVisibility: AuthContextValue['determineFeatureFlagVisibility'],
): TableColumn<Equipment>[] {
  return [
    {
      Header: <Translation>{(t) => <>{t('location')}</>}</Translation>,
      id: 'metadata.locationId',
      accessor: (data) => data.locationMetadata?.locationDisplayName,
      filterOptions: {
        type: TableFilterTypes.locationSelect,
        hidden: false,
        showOnMobile: true,
      },
    },
    {
      Header: <Translation>{(t) => <>{t('address')}</>}</Translation>,
      id: 'locationAddress.line1',
      accessor: (data) => data.locationAddress?.line1,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) => formatAddressAsOneLine(data.row.original?.locationAddress),
      Cell: (data) => <AddressColumn addressObject={data.row.original?.locationAddress} />,
    },
    {
      Header: <Translation>{(t) => <>{t('city')}</>}</Translation>,
      id: 'locationAddress.city',
      accessor: (data) => data.locationAddress?.city,
      disableSortBy: true,
    },
    {
      Header: <Translation>{(t) => <>{t('state')}</>}</Translation>,
      id: 'locationAddress.stateUnabbreviated',
      accessor: (data) => data.locationAddress?.stateUnabbreviated,
      disableSortBy: true,
      toText: (data) => (data?.value && statesAsObject[data?.value]) || 'N/A',
      Cell: (data) => <>{(data?.value && statesAsObject[data?.value]) || 'N/A'}</>,
    },
    {
      Header: <Translation>{(t) => <>{t('zip')}</>}</Translation>,
      id: 'locationAddress.zip',
      accessor: (data) => data.locationAddress?.zip,
      disableSortBy: true,
    },
    {
      id: 'metadata.mainContactUserId',
      Header: <Translation>{(t) => <>{t('mainContact')}</>}</Translation>,
      accessor: (data) => data?.metadata?.mainContactUser?.metadata?.fullName,
      toText: (data) => data?.value,
      Cell: (data) => {
        const fullName = data.row?.original?.metadata?.mainContactUser?.metadata?.fullName;
        const url = data.row?.original?.metadata?.mainContactUser?.metadata?.profilePictureUrl;
        if (fullName) {
          return (
            <div className="flex items-center gap-2">
              <Avatar src={url ?? ''} alt={fullName ?? 'Unknown User'} className="h-8 w-8 bg-blue-700">
                {abbreviateName(fullName)}
              </Avatar>
              {fullName}
            </div>
          );
        } else {
          return <p>Unassigned</p>;
        }
      },
    },
    {
      id: 'metadata.mainContact.phoneNumberWithCountryCode',
      Header: <Translation>{(t) => <>{t('mainContactPhone')}</>}</Translation>,
      disableSortBy: true,
      accessor: (data) => data?.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode,
      toText: (data) => toDisplayPhoneFromPhoneWithCountryCode(data?.value),
      Cell: (data) => <>{toDisplayPhoneFromPhoneWithCountryCode(data?.value)}</>,
    },
    {
      id: 'metadata.mainContact.email',
      Header: <Translation>{(t) => <>{t('mainContactEmail')}</>}</Translation>,
      disableSortBy: true,
      accessor: (data) => data?.metadata?.mainContactUser?.metadata?.email,
    },
    {
      Header: <Translation>{(t) => <>{t('number')}</>}</Translation>,
      id: 'metadata.shortId',
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => (
        <div className="flex justify-between">
          <Link
            className="active font-bold"
            data-testid="equipmentEditButton"
            to={`${Urls.EQUIPMENT}/${data.row.original.metadata?.equipmentId}`}
          >
            {data?.value}
          </Link>
          <EquipmentRowActions {...data}></EquipmentRowActions>
        </div>
      ),
      toText: (data) => data.value,
      filterOptions: {
        type: TableFilterTypes.text,
      },
    },
    {
      Header: <Translation>{(t) => <>{t('name')}</>}</Translation>,
      id: 'metadata.equipmentName',
      accessor: (data) => data.metadata?.equipmentName,
    },
    {
      Header: <Translation>{(t) => <>{t('category')}</>}</Translation>,
      id: 'specMetadata.categoryId',
      accessor: (data) => data.specMetadata?.categoryId,
      toText: (data) => data.row.original.specMetadata?.category?.metadata?.name ?? '',
      Cell: (data) => <>{data.row.original.specMetadata?.category?.metadata?.name}</>,
      filterOptions: {
        type: TableFilterTypes.categorySelect,
        filterType: JsonParam,
        showOnMobile: true,
      },
      width: defaultColumnWidths.normalText,
    },
    {
      Header: <Translation>{(t) => <>{t('subcategory')}</>}</Translation>,
      id: 'metadata.subclasses',
      accessor: (data) => data.metadata?.subclasses,
      disableSortBy: true,
      Cell: (data) => arrayToGrammaticalList(data?.value),
      filterOptions: {
        type: TableFilterTypes.subcategorySelect,
        defaultValue: [],
        filterType: JsonParam,
      },
      width: defaultColumnWidths.normalText,
    },
    {
      Header: <Translation>{(t) => <>{t('manufacturer')}</>}</Translation>,
      id: 'specMetadata.manufacturer',
      accessor: (data) => data.specMetadata?.manufacturer,
      width: defaultColumnWidths.normalText,
    },
    {
      Header: <Translation>{(t) => <>{t('status')}</>}</Translation>,
      id: 'metadata.equipmentStatus',
      accessor: (data) => data.metadata?.equipmentStatus,
      Cell: (d) => <>{getEnumDisplayName(d?.value, equipmentStatusType) || ''}</>,
      toText: (d) => getEnumDisplayName(d?.value, equipmentStatusType) || '',
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(equipmentStatusType, { anyText: 'Any' }),
      },
    },
    {
      Header: <Translation>{(t) => <>{t('equipmentHealth')}</>}</Translation>,
      id: 'metadata.equipmentHealth',
      accessor: (data) => data.metadata?.equipmentHealth,
      Cell: (d) => <>{getEnumDisplayName(d?.value, equipmentHealthType) || ''}</>,
      toText: (d) => getEnumDisplayName(d?.value, equipmentHealthType) || '',
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(equipmentHealthType, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      Header: <Translation>{(t) => <>{t('openRequests')}</>}</Translation>,
      id: 'metadata.openServiceRequestCount',
      accessor: (data) => data.metadata?.openServiceRequestCount,
      disableSortBy: true,
      filterOptions: {
        label: 'Open Service Requests',
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has Open Service Requests', value: 'true' },
          { label: 'No Open Service Requests', value: 'false' },
        ],
      },
    },
    {
      Header: <Translation>{(t) => <>{t(userCan(internalUsers) ? 'clientTags' : 'tags')}</>}</Translation>,
      id: 'tagMetadata.tagId',
      accessor: (data) => data.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.Equipment,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.Equipment}
            label=""
            onSave={async (selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];
              await patchEquipment({
                equipmentId: data.row?.original?.metadata?.equipmentId,
                metadata: { clientTags },
              });
              data.refetchData();
            }}
          />
        );
      },
    },
    {
      Header: <Translation>{(t) => <>{t('mode')}</>}</Translation>,
      id: 'specMetadata.model',
      accessor: (data) => data.specMetadata?.model,
      width: defaultColumnWidths.normalText,
      Cell: (data) => (
        <div className="flex justify-between">
          {userCan(internalUsers) ? (
            <Link to={`${Urls.SPECS}/${data.row.original.metadata?.specId}`}>{data?.value}</Link>
          ) : (
            <>{data?.value}</>
          )}
        </div>
      ),
      toText: (data) => data.value,
    },
    {
      Header: <Translation>{(t) => <>{t('serialNumber')}</>}</Translation>,
      id: 'metadata.serialNumber',
      accessor: (data) => data.metadata?.serialNumber,
    },
    {
      Header: <Translation>{(t) => <>{t('externalId')}</>}</Translation>,
      id: 'metadata.externalId',
      accessor: (data) => data.metadata?.externalId,
    },
    {
      Header: <Translation>{(t) => <>{t('serviceEndDate')}</>}</Translation>,
      id: 'metadata.serviceEndDate',
      accessor: (data) => data.metadata?.serviceEndDate,
      toText: (d) => toESTDateStringFromUnixMillisecondTimestamp(d?.value),
      Cell: (d) => <>{toESTDateStringFromUnixMillisecondTimestamp(d?.value)}</>,
      width: defaultColumnWidths.normalText,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      Header: <Translation>{(t) => <>{t('pmCompliance')}</>}</Translation>,
      id: 'metadata.pmCompliance',
      accessor: (data) => data?.metadata?.pmCompliance,
      toText: (d) => d?.value || '',
      Cell: (d) => <>{d?.value}</>,
      disableSortBy: true,
    },
    {
      Header: <Translation>{(t) => <>{t('usefulEndOfLifeDate')}</>}</Translation>,
      id: 'metadata.usefulLifeEndDate',
      accessor: (data) => {
        const usefulLifeEndDate = data.metadata?.usefulLifeEndDate;
        return (typeof usefulLifeEndDate === 'number' && dayjs(usefulLifeEndDate).format('MM/DD/YYYY')) || null;
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
        options: [dateRangeOptions.custom],
      },
    },
    {
      Header: <Translation>{(t) => <>{t('manufacturerEol')}</>}</Translation>,
      id: 'specMetadata.manufacturerEOL',
      accessor: (data) => data.specMetadata?.manufacturerEOL,
      toText: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      Cell: (d) => <>{toDateStringFromUnixMillisecondTimestamp(d?.value)}</>,
      width: defaultColumnWidths.normalText,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      Header: <Translation>{(t) => <>{t('vendorLimitedSupport')}</>}</Translation>,
      id: 'specMetadata.vendorLimitedSupportDate',
      accessor: (data) => data.specMetadata?.vendorLimitedSupportDate,
      toText: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      Cell: (d) => <>{toDateStringFromUnixMillisecondTimestamp(d?.value)}</>,
      width: defaultColumnWidths.normalText,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      Header: <Translation>{(t) => <>{t('locationNo')}</>}</Translation>,
      id: 'locationMetadata.shortId',
      accessor: (data) => data.locationMetadata?.shortId,
    },
    {
      Header: <Translation>{(t) => <>{t('parentLocation')}</>}</Translation>,
      id: 'metadata.location.metadata.parentLocation.metadata.locationDisplayName',
      accessor: (data) => data?.metadata?.location?.metadata?.parentLocation?.metadata?.locationDisplayName,
    },
    {
      Header: <Translation>{(t) => <>{t('installationDate')}</>}</Translation>,
      id: 'metadata.installationDate',
      accessor: (data) => data.metadata?.installationDate,
      toText: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      Cell: (d) => <>{toDateStringFromUnixMillisecondTimestamp(d?.value)}</>,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
      width: defaultColumnWidths.normalText,
    },
    {
      Header: <Translation>{(t) => <>{t('warrantyOnDate')}</>}</Translation>,
      id: 'metadata.warrantyOnDate',
      accessor: (data) => data.metadata?.warrantyOnDate,
      toText: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      Cell: (d) => <>{toDateStringFromUnixMillisecondTimestamp(d?.value)}</>,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
      width: defaultColumnWidths.normalText,
    },
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentFieldsWarranty)
      ? [
          {
            Header: <Translation>{(t) => <>{t('warrantyOffDate')}</>}</Translation>,
            id: 'metadata.warrantyOffDate',
            accessor: (data) => data.metadata?.warrantyOffDate,
            toText: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
            Cell: (d) => <>{toDateStringFromUnixMillisecondTimestamp(d?.value)}</>,
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('warrantyInfo')}</>}</Translation>,
            id: 'metadata.warrantyInfo',
            accessor: (data) => data.metadata?.warrantyInfo,
            width: defaultColumnWidths.largeText,
            toText: (data) => data?.value,
            Cell: (data) => <TableCellExpand data-testid="viewAllOfTitle">{data?.value}</TableCellExpand>,
            disableSortBy: true,
          },
        ]
      : []),
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentFieldCostCenter)
      ? [
          {
            Header: <Translation>{(t) => <>{t('costCenter')}</>}</Translation>,
            id: 'metadata.costCenter',
            accessor: (data) => data.metadata?.costCenter,
            width: defaultColumnWidths.normalText,
            toText: (data) => data?.value,
            Cell: (data) => <>{data?.value}</>,
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.text,
            },
          },
        ]
      : []),
    {
      Header: <Translation>{(t) => <>{t('tid')}</>}</Translation>,
      id: 'metadata.tid',
      accessor: (data) => data.metadata?.tid,
      width: defaultColumnWidths.normalText,
    },
    {
      id: 'metadata.assetPrice',
      Header: <Translation>{(t) => <>{t('assetPrice')}</>}</Translation>,
      accessor: (data) => data.metadata?.assetPrice,
      toText: (data) => centsToMoney(data?.value),
      Cell: (data) => <>{centsToMoney(data?.value)}</>,
    },
    {
      id: 'metadata.serviceTotal',
      Header: <Translation>{(t) => <>{t('serviceTotal')}</>}</Translation>,
      accessor: (data) => data.metadata?.serviceTotal,
      toText: (data) => centsToMoney(data?.value),
      Cell: (data) => <>{centsToMoney(data?.value)}</>,
    },
    {
      Header: <Translation>{(t) => <>{t('options')}</>}</Translation>,
      disableSortBy: true,
      id: 'options',
      accessor: (data) => data.options,
      toText: (d) => standardEntityOptionsSerializerAndDeserializer.serializer(d.row.original?.options || {}),
      Cell: (d) => <>{standardEntityOptionsSerializerAndDeserializer.serializer(d.row.original?.options || {})}</>,
      width: defaultColumnWidths.largeText,
    },
    ...(!userCan(customers)
      ? [
          {
            id: 'referredByOrg',
            filterOptions: {
              label: <Translation>{(t) => <>{t('referredByMyOrganization')}</>}</Translation>,
              type: TableFilterTypes.select,
              options: [
                { label: 'Any', value: '' },
                { label: 'Yes', value: 'true' + auth?.organizationId },
                { label: 'No', value: 'false' + auth?.organizationId },
              ],
            },
          },
        ]
      : []),
    ...(userCan(internalUsers)
      ? [
          {
            Header: <Translation>{(t) => <>{t('organization')}</>}</Translation>,
            id: 'metadata.organizationId',
            accessor: (data) => data.organizationMetadata?.organizationName,
            toText: (data) => data?.value,
            Cell: (data) => (
              <>
                {data?.value} <TierIndicator tier={data?.row?.original?.organizationMetadata?.tier} />
              </>
            ),
            width: defaultColumnWidths.normalText,
            filterOptions: {
              type: TableFilterTypes.organizationSelect,
              label: 'Client',
              typeOfOrganizationToFind: clientValueForFilter,
            },
          },
          {
            Header: <Translation>{(t) => <>{t('clientOrganizationType')}</>}</Translation>,
            id: 'organizationMetadata.clientOrganizationType',
            accessor: (data) => data.organizationMetadata?.clientOrganizationType,
            Cell: (d) => <>{getEnumDisplayName(d?.value, clientOrganizationTypes) || ''}</>,
            toText: (d) => getEnumDisplayName(d?.value, clientOrganizationTypes) || '',
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(clientOrganizationTypes, { anyText: 'Any' }),
            },
          },
          {
            Header: <Translation>{(t) => <>{t('industry')}</>}</Translation>,
            id: 'organizationMetadata.industry',
            accessor: (data) => data.organizationMetadata?.industry,
            Cell: (d) => <>{getEnumDisplayName(d?.value, organizationIndustryTypes) || ''}</>,
            toText: (d) => getEnumDisplayName(d?.value, organizationIndustryTypes) || '',
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(organizationIndustryTypes, { anyText: 'Any' }),
            },
          },
          {
            Header: '',
            id: 'agreementFilter',
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.agreementSelect,
              label: <Translation>{(t) => <>{t('agreement')}</>}</Translation>,
              showFilterOptionWhen: (filterMap) => Boolean(filterMap['metadata.organizationId']),
              labelHelper:
                'Select an agreement to see equipment that were on that agreement. This overrides any organization that maybe selected to the organization on the agreement.',
            },
          },
          {
            id: 'coveragesMetadata.coverageStatus',
            filterOptions: {
              label: 'Coverage Status',
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(coverageStatuses, { anyText: 'Any' }),
            },
          },
          {
            Header: '',
            id: 'coverageFilter',
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.select,
              label: 'Agreement Filter Type',
              showFilterOptionWhen: (filterMap) => Boolean(filterMap['metadata.contractId']),
              labelHelper:
                'Select if you want to see all active equipment at the time of the agreement, or equipment with coverage on the agreement',
              options: [
                { label: 'All Equipment On Agreement', value: agreementFilterTypeOptions.allEquipment },
                { label: 'Equipment With Coverage', value: agreementFilterTypeOptions.equipmentWithCoverage },
              ],
            },
          },
          {
            Header: <Translation>{(t) => <>{t('onDate')}</>}</Translation>,
            id: 'coveragesMetadata.onDate',
            accessor: (data) => data.coveragesMetadata,
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
            toText: (d) =>
              toESTDateStringFromUnixMillisecondTimestamp(
                (d.value || []).find((item) => item?.coverageStatus === CoverageStatusType.Effective)?.onDate,
              ),
            Cell: (d) => (
              <NestedArrayPopover
                title="View dates"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(d) => toESTDateStringFromUnixMillisecondTimestamp(d?.onDate)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('offDate')}</>}</Translation>,
            id: 'coveragesMetadata.offDate',
            accessor: (data) => data.coveragesMetadata,
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
            toText: (d) =>
              toESTDateStringFromUnixMillisecondTimestamp(
                (d.value || []).find((item) => item?.coverageStatus === CoverageStatusType.Effective)?.offDate,
              ),
            Cell: (d) => (
              <NestedArrayPopover
                title="View dates"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => toESTDateStringFromUnixMillisecondTimestamp(item?.offDate)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('coveragePrice')}</>}</Translation>,
            id: 'coveragesMetadata.annualizedCoveragePrice',
            accessor: (data) => data.coveragesMetadata,
            filterOptions: {
              type: TableFilterTypes.currencyInput,
            },
            toText: (d) =>
              convertDailyPriceToAnnualized(
                (d.value || []).find((item) => item?.coverageStatus === CoverageStatusType.Effective)?.dailyCoveragePrice,
              ),
            Cell: (d) => (
              <NestedArrayPopover
                title="View all pricing"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => convertDailyPriceToAnnualized(item?.dailyCoveragePrice)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('maintenanceContractFee')}</>}</Translation>,
            id: 'coveragesMetadata.dailyMaintenanceContractFee',
            accessor: (data) => data.coveragesMetadata,
            toText: (d) =>
              convertDailyPriceToAnnualized(
                (d.value || []).find((item) => item?.coverageStatus === CoverageStatusType.Effective)?.dailyMaintenanceContractFee,
              ),
            Cell: (d) => (
              <NestedArrayPopover
                title="View all pricing"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => convertDailyPriceToAnnualized(item?.dailyMaintenanceContractFee)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('managedServicesFee')}</>}</Translation>,
            id: 'metadata.dailyManagedServicesFee',
            accessor: (data) => data.metadata?.dailyManagedServicesFee,
            toText: (data) => convertDailyPriceToAnnualized(data?.value),
            Cell: (data) => <>{convertDailyPriceToAnnualized(data?.value)}</>,
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('managedServiceDispatchFee')}</>}</Translation>,
            id: 'metadata.dailyServiceDispatchFee',
            accessor: (data) => data.metadata?.dailyServiceDispatchFee,
            toText: (data) => convertDailyPriceToAnnualized(data?.value),
            Cell: (d) => <>{convertDailyPriceToAnnualized(d.row.original?.metadata?.dailyServiceDispatchFee)}</>,
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('conditions')}</>}</Translation>,
            id: 'coveragesMetadata.conditions',
            accessor: (data) => data.coveragesMetadata?.conditions,
            disableSortBy: true,
            toText: (d) => d.row.original?.coveragesMetadata?.[0]?.conditions ?? '',
            Cell: (d) => (
              <NestedArrayPopover
                title="View all conditions"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => item?.conditions}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('exclusions')}</>}</Translation>,
            id: 'coveragesMetadata.exclusions',
            accessor: (data) => data.coveragesMetadata?.exclusions,
            disableSortBy: true,
            toText: (d) => d.row.original?.coveragesMetadata?.[0]?.exclusions ?? '',
            Cell: (d) => (
              <NestedArrayPopover
                title="View all exclusions"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => item?.exclusions}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: <Translation>{(t) => <>{t('pmCadence')}</>}</Translation>,
            id: 'metadata.equipsCoveragePmCadence',
            accessor: (data) => data.metadata?.equipsCoveragePmCadence,
            toText: (d) => capitalize(d.row.original?.metadata?.equipsCoveragePmCadence),
            Cell: (d) => <>{capitalize(d.row.original?.metadata?.equipsCoveragePmCadence)}</>,
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(equipsCoveragePmCadenceType, { anyText: 'Any', allowNull: true }),
            },
          },
          {
            Header: <Translation>{(t) => <>{t('monthsToPmEquipment')}</>}</Translation>,
            id: 'metadata.monthsToPmEquipment',
            accessor: (data) => data.metadata?.monthsToPmEquipment,
            toText: (d) => {
              const months = (d.row.original?.metadata?.monthsToPmEquipment ?? []).sort((a, b) => {
                const weightA = monthsToPmEquipmentType[a]?.weight || 0;
                const weightB = monthsToPmEquipmentType[b]?.weight || 0;
                return weightA - weightB;
              });
              return months.join(', ');
            },
            Cell: (d) => {
              const months = (d.row.original?.metadata?.monthsToPmEquipment ?? []).sort((a, b) => {
                const weightA = monthsToPmEquipmentType[a]?.weight || 0;
                const weightB = monthsToPmEquipmentType[b]?.weight || 0;
                return weightA - weightB;
              });
              return <>{months.join(', ')}</>;
            },
          },
        ]
      : []),
    {
      Header: <Translation>{(t) => <>{t('chronicIssues')}</>}</Translation>,
      id: 'metadata.hasChronicIssues',
      accessor: (data) => data.metadata?.hasChronicIssues,
      disableSortBy: true,
      toText: (d) => (d.row.original?.metadata?.hasChronicIssues ? 'Yes' : 'No'),
      Cell: (d) => <>{d.row.original?.metadata?.hasChronicIssues ? 'Yes' : 'No'}</>,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
    },
    ...(userCan(rolesThatCanViewCoverageData)
      ? [
          {
            Header: <Translation>{(t) => <>{t('provider')}</>}</Translation>,
            id: 'metadata.providerId',
            accessor: (data) => data.metadata?.providerId,
            width: defaultColumnWidths.largeText,
            Cell: (data) => <>{data.row.original?.metadata?.provider?.metadata?.organizationName || ''}</>,
            toText: (data) => data.row.original?.metadata?.provider?.metadata?.organizationName || '',
            filterOptions: {
              type: TableFilterTypes.organizationSelect,
              hidden: false,
              typeOfOrganizationToFind: providerValueForFilter,
            },
          },
          {
            Header: <Translation>{(t) => <>{t('coverage')}</>}</Translation>,
            id: 'coverages',
            accessor: (data) => data.coverages,
            width: defaultColumnWidths.largeText,
            disableSortBy: true,
            Cell: EquipmentCoverageCell,
            toText: (data) => equipmentCoverageText({ data }).text,
          },
          {
            Header: <Translation>{(t) => <>{t('coverageExpired')}</>}</Translation>,
            id: 'metadata.coveragesMetadata.expired',
            accessor: (data) => data.coverages,
            toText: (d) => {
              const expired = d.row?.original?.coveragesMetadata?.[0]?.expired;
              return expired === true ? 'Yes' : expired === false ? 'No' : 'N/A';
            },
            Cell: (d) => {
              const expired = d.row?.original?.coveragesMetadata?.[0]?.expired;
              return <>{expired === true ? 'Yes' : expired === false ? 'No' : 'N/A'}</>;
            },
          },
        ]
      : []),
    {
      Header: <Translation>{(t) => <>{t('notes')}</>}</Translation>,
      id: 'notes',
      accessor: (data) => data.notes,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: async (d) => {
        return standardNoteSerializerAndDeserializer.serializer(d.row.original?.notes || []);
      },
      Cell: (d) => (
        <TableCellNotes
          entityId={d.row.original?.metadata?.equipmentId || ''}
          parentLabel={getEnumValue(allowedNoteParentLabels.equipment)}
          noteQueryFunction={getEquipmentNotes}
          noteText={d.row.original?.notes?.[0]?.metadata?.message}
        />
      ),
    },
    {
      id: 'sublocationIds',
    },
    {
      id: 'equipmentId',
      Header: <Translation>{(t) => <>{t('equipmentId')}</>}</Translation>,
      accessor: (data) => data.equipmentId,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) => data.value || '',
      Cell: (data) => <TableCellCopyData data={data.value}>{data.value}</TableCellCopyData>,
      filterOptions: {
        type: TableFilterTypes.text,
        hidden: !userCan(internalUsers),
      },
    },
  ];
}
