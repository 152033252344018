import React, { lazy, Suspense, useState } from 'react';
import { queryAlerts, UserAuthorizationRoleEnum, View } from '@equips/entities-schema';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Book from '@mui/icons-material/Book';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { getOrganizationLogo } from '../../../graphql/queries/organizationGraphQLQueries';
import Logo from '../../../images/logo-white-text.png';
import Urls from '../../../routes/Urls';
import { useAuth } from '../../auth/AuthContext';
import { getCurrentDateAndMonth } from '../../functions/dateFunctions';
import useRouter from '../../hooks/useRouter';
import Banner from '../Banners/Banner';
import HolidayBanner from '../Banners/HolidayBanner';
import { TrialBanner } from '../Banners/TrialBanner';
import './NavBar.css';
import SimpleModal from '../Modal/SimpleModal';
import SavedViewSelect from '../Selects/SavedViewSelect';
import { useNavData } from '../../providers/NavDataProvider';
import config from '../../../config';
import useSingleAndDoubleClicks from '../../hooks/useSingleAndDoubleClicks';
import TechnicalDifficultiesBanner from '../Banners/TechnicalDifficultiesBanner';
import OmniSearch from './OmniSearch';
import UnsupportedBrowser from './UnsupportedBrowser';
import { UserMenu } from './UserMenu';
import useNavItems from './useNavItems';

// This is annoying and can clutter up the logs when trying to test locally.
const isLocal = config?.stage === 'local';

// In the event of AWS outage break glass and then set this to true and redeploy
const enableTechnicalDifficultiesBanner = false;

const MobileMenu = lazy(() => import('./MobileMenu'));

const NavItems = ({ item, setMenu, openMenu, pathname }) => {
  const { content, nested } = item;

  return (
    <li
      role="none"
      className="menu-group hidden rounded-lg transition-all duration-100 ease-in-out hover:bg-white-50 md:inline-block"
      key={content}
    >
      <div className="block px-2">
        {nested.length === 1 && content === nested[0].content && (
          <Link
            data-testid={`nav${content}`}
            onClick={() => {
              setMenu('');
              nested[0].onClick && nested[0].onClick();
            }}
            className="flex p-3 text-white hover:text-white"
            role="menuitem"
            to={nested[0].to || pathname}
          >
            {content}
          </Link>
        )}

        {content !== nested[0].content && (
          <button
            data-testid={`nav${content}`}
            type="button"
            className="py-3"
            onClick={() => setMenu((state) => (state === content ? '' : content))}
          >
            {content}
            <KeyboardArrowDown fontSize="small" />
          </button>
        )}
      </div>
      {content !== nested[0].content && (
        <div role="menu" className={`menu-group-container absolute z-50 ${openMenu === content ? 'block' : 'hidden'}`} aria-label={content}>
          <ul role="none" className="rounded-b-lg bg-equipsNavyBlue shadow-lg" style={{ minWidth: 150 }}>
            {nested.map(({ content, to, onClick }) => (
              <li role="none" className="px-1 transition-all duration-100 ease-in-out hover:bg-white-50" key={to || content}>
                <Link
                  data-testid={`nav${content}`}
                  onClick={() => {
                    setMenu('');
                    onClick && onClick();
                  }}
                  className="flex p-3 text-white hover:text-white"
                  role="menuitem"
                  to={to || pathname}
                >
                  {content}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default function NavBar({ children }) {
  const { t } = useTranslation();
  const [openMenu, setMenu] = useState('');
  const [visible, setVisible] = useState(false);
  const [openSavedViewSelect, setOpenSavedViewSelect] = useState(false);

  const authData = useAuth();
  const { auth, isTrial, userCan, isSystemImportUser, isAasUser } = authData;

  const {
    location: { pathname },
  } = useRouter();

  const navData = useNavData();

  const onRedirectToSavedView = (selectedSavedView?: View) => {
    const savedView = selectedSavedView || navData.favoriteSavedView;
    savedView && navData.redirectToSavedView(savedView);
    openSavedViewSelect && setOpenSavedViewSelect(false);
  };

  const onClick = () => (navData.favoriteSavedView ? onRedirectToSavedView() : setOpenSavedViewSelect(true));
  const onDoubleClick = () => setOpenSavedViewSelect(true);

  const { handleClick, handleDoubleClick } = useSingleAndDoubleClicks({ onClick, onDoubleClick });

  const { data } = useQuery(
    ['queryAlertsCount', auth?.userId],
    () =>
      queryAlerts(
        {
          search: {
            includeTotalCount: true,
            filters: [{ metadata: { userId: [{ keyword: [{ term: auth?.userId }] }] } }],
          },
        },
        {
          query: `alertId`,
        },
      ),
    { enabled: !!auth?.userId, refetchInterval: isLocal ? false : 60000 },
  );

  const referringOrganizationId = auth?.organization?.metadata?.referringOrganizationId || '';

  const { data: referringOrganizationData } = useQuery(
    ['getOrganizationLogo', referringOrganizationId],
    () => getOrganizationLogo({ organizationId: referringOrganizationId }),
    {
      enabled: !!referringOrganizationId,
      select: (data) => data?.data,
    },
  );

  const alertCount = data?.data?.alert?.totalCount || 0;
  const navItems = useNavItems(authData);

  const SystemImportUserWarningBanner = () => (
    <Banner bg="bg-equipsOrange" textColor="text-white" text="You are logged in as the System Import User." />
  );

  const ProposedUserBanner = () => (
    <Banner
      data-testid="proposedUserBanner"
      bg="bg-equipsOrange"
      textColor="text-white"
      text="Your account is awaiting approval by an administrator and is temporarily in read-only mode."
    />
  );

  const isProposedUser = userCan([UserAuthorizationRoleEnum.ProposedCustomer, UserAuthorizationRoleEnum.ProposedProvider]);

  const { month, date } = getCurrentDateAndMonth();
  const currentYear = new Date().getFullYear();

  const allowedUnauthedRoutes = ['/checkin', 'service-requests/create'];
  const isRouteAllowed = allowedUnauthedRoutes.some((route) => pathname.includes(route));

  const referringOrganizationLogo = referringOrganizationData?.organizations?.data?.[0]?.metadata?.logo?.presignedGetUrl;
  const defaultLogo = referringOrganizationLogo || Logo;

  const logo = auth?.organization?.metadata?.logo?.presignedGetUrl || defaultLogo;

  return (
    <>
      {(auth || isRouteAllowed) && (
        <ClickAwayListener
          onClickAway={() => {
            setMenu('');
            setVisible(false);
          }}
        >
          <nav data-testid="menu-left-side" role="navigation" className="linear-blue w-full text-sm text-white print:hidden">
            <div className="mx-auto max-w-full px-4 md:px-12">
              <div className="flex flex-wrap justify-between">
                <div className="flex">
                  {auth && !userCan([UserAuthorizationRoleEnum.ProviderEmployee]) && (
                    <div className="mr-4 flex items-center md:hidden">
                      <button data-testid="toggleMobileMenu" type="button" onClick={() => setVisible((state) => !state)}>
                        <span className="sr-only">{t('openMenu')}</span>
                        <MenuIcon />
                      </button>
                    </div>
                  )}

                  <div className="flex flex-shrink-0 items-center">
                    <Link
                      onClick={() => {
                        setMenu('');
                        const userIsOnTheDashboardRefreshToResetStateOfWizard = pathname === Urls.HOME;
                        !isAasUser && userIsOnTheDashboardRefreshToResetStateOfWizard && window.location.reload();
                      }}
                      to={!userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin]) && isAasUser ? Urls.AAS_CLAIMS : Urls.HOME}
                    >
                      <img alt="Equips Logo" className="object-cover" src={logo} style={{ maxHeight: 60 }} />
                    </Link>
                  </div>

                  <div aria-label="Equips Navigation" role="menubar" className="md:ml-6 md:flex md:items-center md:space-x-4">
                    <ul>
                      <li className="sr-only focus:not-sr-only">
                        <a href="#content">{t('skipToContent')}</a>
                      </li>
                      {navItems.map((item) => (
                        <NavItems key={item.content} item={item} openMenu={openMenu} setMenu={setMenu} pathname={pathname} />
                      ))}
                    </ul>
                  </div>
                </div>

                <div aria-label="Equips Action Buttons" className="my-2 mr-2 flex items-center sm:my-0 md:mr-0">
                  {auth && navData.quickViews && (
                    <div role="button" tabIndex={0} onClick={handleClick} onDoubleClick={handleDoubleClick} onKeyPress={() => {}}>
                      <Tooltip title={navData.favoriteSavedView ? `${navData.favoriteSavedView.metadata?.name}` : t('quickViews')}>
                        <span>
                          <Button disabled={navData.isFetchingUserData || navData.isLoadingUserData}>
                            <Book className="text-white" />
                          </Button>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {auth && (
                    <Tooltip title={alertCount < 1 ? t('todoList') : t('newToDo')}>
                      <Link to={Urls.ALERTS}>
                        <Badge badgeContent={alertCount} overlap="circular" color="secondary">
                          <FormatListBulleted className="text-white" />
                        </Badge>
                      </Link>
                    </Tooltip>
                  )}
                  {auth && auth.initialized && !userCan([UserAuthorizationRoleEnum.ProviderEmployee]) && (
                    <>
                      <Suspense fallback={<div />}>
                        <OmniSearch />
                      </Suspense>
                    </>
                  )}
                  {auth && (
                    <div>
                      <UserMenu />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {visible && !userCan([UserAuthorizationRoleEnum.ProviderEmployee]) && <MobileMenu left={navItems} setVisible={setVisible} />}
          </nav>
        </ClickAwayListener>
      )}
      <UnsupportedBrowser />
      <SimpleModal handleClose={() => setOpenSavedViewSelect(false)} isOpen={openSavedViewSelect}>
        <SavedViewSelect navigate={onRedirectToSavedView} />
      </SimpleModal>
      {enableTechnicalDifficultiesBanner && <TechnicalDifficultiesBanner />}
      {isSystemImportUser && <SystemImportUserWarningBanner />}
      {isProposedUser && <ProposedUserBanner />}
      {isTrial && <TrialBanner />}
      <HolidayBanner
        holiday="Christmas"
        start={`12/24/${currentYear}`}
        end={`12/28/${currentYear}`}
        showBanner={(month === 12 && date >= 17) || (month === 1 && date <= 2)}
      />
      {/* <HolidayBanner
        holiday="New Years"
        start={`1/1/${currentYear + 1}`}
        end={`1/4/${currentYear + 1}`}
        showBanner={(month === 12 && date >= 28) || (month === 1 && date <= 3)}
      /> */}
      {children}
    </>
  );
}
