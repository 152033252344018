import React from 'react';
import {
  ContractStatusType,
  CoverageStatusType,
  ChargeAdjudicationResultType,
  UserTagsType,
  ContractTypeEnum,
  SubscriptionStatus,
  BillingPeriod,
  SubscriptionType,
  EmailInvoicesToEnumType,
  ServiceRequestStatusType,
  IncidentFixesEnum,
  IncidentActionsEnum,
  RequestOriginationType,
  UserFeatureFlagEnum,
  EquipmentStatusType,
  EquipmentHealthType,
  ServiceRequestSizeType,
  ServiceRequestPriorityType,
  NotificationMethodEnum,
  AasClaimStatusType,
  AasProcessingStatusType,
  ServiceContractStatus,
  ChecklistStepType,
  AasFailGroupEnumType,
  EquipsCoveragePmCadenceType,
  OrganizationTier,
  MonthsToPmEquipmentType,
} from '@equips/entities-schema';
import { toTerms } from '../common/functions/elasticSearchHelpers';
import i18n from '../i18n';

export type EnumObjectType<T = string> = { [key: string]: { label?: string; name: string; value: T } };

export const getEnumValue = (innerObject) => (innerObject && innerObject.value) || '';

export const getEnumDisplayName = (value: undefined | string | null, enumObject: EnumObjectType) => {
  if (!value) return '';

  const enumDisplayName = enumObject[value];

  return (enumDisplayName && enumDisplayName.name) || value || '';
};

export const SelectOptionsForEnum = ({
  arrayOfOptions,
  objectOfOptions,
}: {
  arrayOfOptions?: string[];
  objectOfOptions: EnumObjectType;
}) => {
  const a = arrayOfOptions || Object.keys(objectOfOptions);

  return (
    <>
      {a.map((value) => (
        <option key={value} value={value}>
          {getEnumDisplayName(value, objectOfOptions)}
        </option>
      ))}
    </>
  );
};

export function findEnumValueByName<T>(searchString: string, objectOfEnumOptions: EnumObjectType<T>) {
  return Object.entries(objectOfEnumOptions).find(([, { name }]) => name === searchString)?.[1]?.value;
}

export function findEnumNameByValue<T>(searchString: T, objectOfEnumOptions: EnumObjectType<T>) {
  return Object.entries(objectOfEnumOptions).find(([, { value }]) => value === searchString)?.[1]?.name;
}

export const NULL_OPTION_VALUE = '{none}';
export const NOT_NULL_OPTION_VALUE = '{notempty}';

export const enumToOptions = (name: string, value: any, { anyText = '', allowNull = false } = {}) => {
  const options: { label: string; value: string }[] = [];

  if (anyText) {
    options.push({ label: anyText, value: '' });
  }

  if (allowNull) {
    options.push({ label: i18n.t('none'), value: NULL_OPTION_VALUE });
    options.push({ label: i18n.t('isNotEmpty'), value: NOT_NULL_OPTION_VALUE });
  }

  Object.keys(value).forEach((key) => options.push({ label: i18n.t(`enum:${name}.${value[key]}`), value: value[key] }));

  return options;
};

export const enumObjectToSelectOptions = (enumObject: EnumObjectType, { anyText = '', allowNull = false } = {}) => {
  const options: { label: string; value: string }[] = [];

  if (anyText) {
    options.push({ label: anyText, value: '' });
  }

  if (allowNull) {
    options.push({ label: 'None', value: NULL_OPTION_VALUE });
    options.push({ label: 'Is not empty', value: NOT_NULL_OPTION_VALUE });
  }

  Object.entries(enumObject).forEach(([, { name, value }]) => options.push({ label: name, value }));

  return options;
};

export const enumSelectOptionToGraphQLFilter = (value) => {
  if (!value) return undefined;
  const values = Array.isArray(value) ? value : [value];

  if (values[0] === NULL_OPTION_VALUE) {
    return [{ keyword: [{ exists: false }] }, { keyword: [{ term: '' }] }];
  }

  // TODO: This will filter out nulls but if something is blank it will show up still. We should try to fix that
  // But because or filtering requires two separate metdata statements that is gonna be a bit hard to do easily
  if (values[0] === NOT_NULL_OPTION_VALUE) {
    return [{ keyword: [{ exists: true }] }];
  }

  if (values.length > 1) {
    return toTerms(values.filter((x) => x !== NULL_OPTION_VALUE && x !== NOT_NULL_OPTION_VALUE));
  }

  return [{ keyword: [{ term: values[0] }] }];
};

export const roleTypes = { ADMIN: 'ADMIN', PROVIDER: 'PROVIDER', CUSTOMER: 'CUSTOMER' };
const { CUSTOMER, ADMIN, PROVIDER } = roleTypes;

export const rolesWithAuthData = {
  superGlobalAdmin: { name: 'Super Global Admin', type: ADMIN, priority: 100, value: 'superGlobalAdmin' },
  globalAdmin: { name: 'Global Admin', type: ADMIN, priority: 80, value: 'globalAdmin' },
  customerOrganizationAdmin: {
    name: 'Customer Organization Admin',
    type: CUSTOMER,
    priority: 70,
    value: 'customerOrganizationAdmin',
  },
  customerLocationAdmin: { name: 'Customer Location Admin', type: CUSTOMER, priority: 50, value: 'customerLocationAdmin' },
  customerEmployee: { name: 'Customer Employee', type: CUSTOMER, priority: 10, value: 'customerEmployee' },
  proposedCustomer: { name: 'Proposed Customer', type: CUSTOMER, priority: 5, value: 'proposedCustomer' },
  providerOrganizationAdmin: {
    name: 'Provider Organization Admin',
    type: PROVIDER,
    priority: 70,
    value: 'providerOrganizationAdmin',
  },
  providerLocationAdmin: { name: 'Provider Location Admin', type: PROVIDER, priority: 50, value: 'providerLocationAdmin' },
  providerEmployee: { name: 'Provider Employee', type: PROVIDER, priority: 10, value: 'providerEmployee' },
  proposedProvider: { name: 'Proposed Provider', type: PROVIDER, priority: 5, value: 'proposedProvider' },
  emailOnlyAuth: { name: 'Email Only Auth', type: CUSTOMER, priority: 9, value: 'emailOnlyAuth' },
};

export const cognitoStatusOptions = {
  FORCE_CHANGE_PASSWORD: { name: 'Must reset password', value: 'FORCE_CHANGE_PASSWORD' },
  RESET_REQUIRED: { name: 'Must reset password', value: 'RESET_REQUIRED' },
  CONFIRMED: { name: 'Valid', value: 'CONFIRMED' },
  UNCONFIRMED: { name: 'Pending confirmation', value: 'UNCONFIRMED' },
  COMPROMISED: { name: 'Security issue', value: 'COMPROMISED' },
  ARCHIVED: { name: 'Archived', value: 'ARCHIVED' },
};

export const allowedNoteParentLabels = {
  serviceRequest: { name: 'Service Request', value: 'serviceRequest' },
  equipment: { name: 'Equipment', value: 'equipment' },
  location: { name: 'Location', value: 'location' },
  organization: { name: 'Organization', value: 'organization' },
  charge: { name: 'Charge', value: 'charge' },
  contract: { name: 'Agreement', value: 'contract' },
  invoice: { name: 'Invoice', value: 'invoice' },
};

export const noteAuthorizationLevels = {
  equipsOnly: { name: 'Equips only', value: 'equipsOnly' },
  clientAndEquips: { name: 'My Organization', value: 'clientAndEquips' },
  providerAndEquips: { name: 'Provider and Equips', value: 'providerAndEquips' },
  all: { name: 'Everyone', value: 'all' },
};

export const chargeStatuses = {
  pendingReview: { name: 'Pending review', value: 'pendingReview' },
  rejected: { name: 'Rejected', value: 'rejected' },
  readyForAdjudication: { name: 'Ready for adjudication', value: 'readyForAdjudication' },
  readyToInvoiceClient: { name: 'Ready for QuickBooks invoice', value: 'readyToInvoiceClient' },
  readyToCreditMemoClient: { valid: false, name: 'Ready for QuickBooks credit memo', value: 'readyToCreditMemoClient' },
  clientInvoiceCreated: { name: 'QuickBooks invoice created', value: 'clientInvoiceCreated' },
  clientCreditMemoCreated: { valid: false, name: 'QuickBooks credit memo created', value: 'clientCreditMemoCreated' },
  clientInvoiceIssued: { name: 'QuickBooks invoice issued', value: 'clientInvoiceIssued' },
  clientInvoiceIssuedExternally: { name: 'Invoiced externally', value: 'clientInvoiceIssuedExternally' },
  clientCreditMemoIssued: { valid: false, name: 'QuickBooks credit memo issued', value: 'clientCreditMemoIssued' },
  clientPaymentReceived: { name: 'QuickBooks invoice paid', value: 'clientPaymentReceived' },
  readyToPayProvider: { name: 'Ready for QuickBooks bill', value: 'readyToPayProvider' },
  providerPaymentCreated: { name: 'QuickBooks bill created', value: 'providerPaymentCreated' },
  providerPaymentIssued: { name: 'QuickBooks bill paid', value: 'providerPaymentIssued' },
  providerCreditMemoIssued: { name: 'Provider credit memo issued', value: 'providerCreditMemoIssued' },
};

export const aasChargeStatuses = {
  approved: { name: 'Approved', value: 'approved' },
  denied: { name: 'Denied', value: 'denied' },
  new: { name: 'New', value: 'new' },
  pending: { name: 'Pending', value: 'pending' },
};

export const chargeLifecycles = {
  clientOnly: { name: 'Client only', value: 'clientOnly' },
  providerOnly: { name: 'Provider only', value: 'providerOnly' },
  clientThenProvider: { name: 'Client then provider', value: 'clientThenProvider' },
};

export const chargeTypes = {
  serviceCharge: { name: 'Service charge', value: 'serviceCharge' },
  programFee: { name: 'Program fee', value: 'programFee' },
};

export const chargeAdjudicationResultTypes = {
  approved: { value: ChargeAdjudicationResultType.Approved, name: 'Approved' },
  denied: { value: ChargeAdjudicationResultType.Denied, name: 'Denied' },
  rejected: { value: ChargeAdjudicationResultType.Rejected, name: 'Rejected' },
};

export const programChargeOriginatingStatus = chargeStatuses.pendingReview.value;

export const chargeDescriptionOptions = {
  labor: { value: 'labor', name: 'Labor' },
  parts: { value: 'parts', name: 'Parts' },
  travel: { value: 'travel', name: 'Travel' },
  mileage: { value: 'mileage', name: 'Mileage' },
  tax: { value: 'tax', name: 'Tax' },
  shipping: { value: 'shipping', name: 'Shipping' },
  supplies: { value: 'supplies', name: 'Supplies' },
  fuel: { value: 'fuel', name: 'Fuel' },
  other: { value: 'other', name: 'Other' },
  programCharge: { value: 'programCharge', name: 'Program charge' },
  deductibleCredit: { value: 'deductibleCredit', name: 'Deductible' },
  credit: { value: 'credit', name: 'Credit' },
};

export const organizationIndustryTypes = {
  financial: { value: 'financial', name: 'Financial' },
  healthcare: { value: 'healthcare', name: 'Healthcare' },
  general: { value: 'general', name: 'General' },
};

export const clientOrganizationTypes = {
  equipsBasic: { value: 'equipsBasic', name: 'Equips Basic' },
  equipsCoverage: { value: 'equipsCoverage', name: 'Equips Coverage' },
  equipsFieldDispatch: { value: 'equipsFieldDispatch', name: 'Equips Field Dispatch' },
  equipsWorkOrderManagement: { value: 'equipsWorkOrderManagement', name: 'Equips Work Order Management' },
  equipsHomeWarranty: { value: 'equipsHomeWarranty', name: 'Equips Home Warranty' },
};

export const serviceRequestDispatchStatuses = {
  internalDispatch: { value: 'internalDispatch', name: 'In-house', description: 'Waiting on internal review' },
  equipsDispatch: { value: 'equipsDispatch', name: 'Equips processing', description: 'Equips is finding the best provider' },
  providerDispatch: { value: 'providerDispatch', name: 'Dispatched', description: 'The provider has been notified.' },
  serviceComplete: { value: 'serviceComplete', name: 'Completed', description: 'Service completed' },
  followUp: { value: 'followUp', name: 'Needs follow up', description: 'Follow up needed' },
  /** @deprecated Now tracked in billingStatus */
  invoiced: { value: 'invoiced', name: 'Invoiced', description: 'The invoice has been received.' },
  awaitingPayment: { name: 'Awaiting Payment', value: 'awaitingPayment', description: 'Awaiting payment' },
  closed: { value: 'closed', name: 'Closed', description: 'Request closed' },
  proposed: { value: 'proposed', name: 'Proposed', description: 'Request needs COAs approval' },
  canceled: { value: 'canceled', name: 'Canceled', description: 'Request canceled' },
  open: { value: 'internalDispatch', name: 'Open' },
  inProgress: { value: 'inProgress', name: 'In progress' },
  onHold: { value: 'onHold', name: 'On hold' },
  newClaim: { value: 'internalDispatch', name: 'New' },
};

export const aasClaimStatuses = {
  open: { value: AasClaimStatusType.Open, name: 'Open' },
  closed: { value: AasClaimStatusType.Closed, name: 'Closed' },
  reopened: { value: AasClaimStatusType.Reopened, name: 'Re-opened' },
};

export const aasProcessingStatuses = {
  authorization: { value: AasProcessingStatusType.Authorization, name: 'Authorization' },
  repair: { value: AasProcessingStatusType.Repair, name: 'Repair' },
  reauthorization: { value: AasProcessingStatusType.Reauthorization, name: 'Reauthorization' },
  denied: { value: AasProcessingStatusType.Denied, name: 'Denied' },
  inactive: { value: AasProcessingStatusType.Inactive, name: 'Inactive' },
  complete: { value: AasProcessingStatusType.Complete, name: 'Complete' },
};

export const aasFailGroups = {
  [AasFailGroupEnumType.AcAndHeating]: { value: AasFailGroupEnumType.AcAndHeating, name: 'A/C & HEATING' },
  [AasFailGroupEnumType.Appliances]: { value: AasFailGroupEnumType.Appliances, name: 'APPLIANCES' },
  [AasFailGroupEnumType.CeilingFan]: { value: AasFailGroupEnumType.CeilingFan, name: 'CEILING FAN' },
  [AasFailGroupEnumType.DoorBell]: { value: AasFailGroupEnumType.DoorBell, name: 'DOOR BELL' },
  [AasFailGroupEnumType.Electrical]: { value: AasFailGroupEnumType.Electrical, name: 'ELECTRICAL' },
  [AasFailGroupEnumType.PlumbingSystem]: { value: AasFailGroupEnumType.PlumbingSystem, name: 'PLUMBING SYSTEM' },
  [AasFailGroupEnumType.Roofing]: { value: AasFailGroupEnumType.Roofing, name: 'ROOFING' },
  [AasFailGroupEnumType.SepticSystem]: { value: AasFailGroupEnumType.SepticSystem, name: 'SEPTIC SYSTEM' },
  [AasFailGroupEnumType.ServiceFee]: { value: AasFailGroupEnumType.ServiceFee, name: 'SERVICE FEE' },
  [AasFailGroupEnumType.SmokeDetector]: { value: AasFailGroupEnumType.SmokeDetector, name: 'SMOKE DETECTOR' },
  [AasFailGroupEnumType.SolarEnergySystem]: { value: AasFailGroupEnumType.SolarEnergySystem, name: 'SOLAR ENERGY SYSTEM' },
  [AasFailGroupEnumType.SpaAndPool]: { value: AasFailGroupEnumType.SpaAndPool, name: 'SPA & POOL' },
  [AasFailGroupEnumType.WaterHeater]: { value: AasFailGroupEnumType.WaterHeater, name: 'WATER HEATER' },
  [AasFailGroupEnumType.WaterSoftener]: { value: AasFailGroupEnumType.WaterSoftener, name: 'WATER SOFTENER' },
  [AasFailGroupEnumType.WellPump]: { value: AasFailGroupEnumType.WellPump, name: 'WELL PUMP' },
  [AasFailGroupEnumType.WindEnergySystem]: { value: AasFailGroupEnumType.WindEnergySystem, name: 'WIND ENERGY SYSTEM' },
  [AasFailGroupEnumType.WineRoom]: { value: AasFailGroupEnumType.WineRoom, name: 'WINE ROOM' },
};

export const organizationOptInWorkOrderStatuses = {
  onHold: { value: 'onHold', name: 'On Hold (blocked)' },
  proposed: { value: ServiceRequestStatusType.Proposed, name: 'Proposed' },
  canceled: { value: ServiceRequestStatusType.Canceled, name: 'Canceled' },
};

export const serviceRequestBillingStatuses = {
  invoiced: { value: 'invoiced', name: 'Invoiced', description: 'The invoice has been received.' },
};

export const userUnsubscribedNotificationTemplatesTypes = {
  clientVisibleNoteAddedToServiceRequest: { value: 'clientVisibleNoteAddedToServiceRequest', name: 'Note added to Service Request' },
  dispatchEmailToAssignedInternalUser: { value: 'dispatchEmailToAssignedInternalUser', name: 'Service Request assigned to me' },
  createdNotificationToCustomer: { value: 'createdNotificationToCustomer', name: 'Service Request created' },
  customerServiceRequestChange: { value: 'customerServiceRequestChange', name: 'Service Request status changed' },
  dispatchNotificationToCustomer: { value: 'dispatchNotificationToCustomer', name: 'Service Request is dispatched' },
  customerServiceRequestStatusFollowUp: {
    value: 'customerServiceRequestStatusFollowUp',
    name: `Service Request status changed to "${serviceRequestDispatchStatuses.followUp.name}"`,
  },
  customerServiceRequestStatusAwaitingPayment: {
    value: 'customerServiceRequestStatusAwaitingPayment',
    name: `Service Request status changed to "${serviceRequestDispatchStatuses.awaitingPayment.name}"`,
  },
  customerServiceRequestStatusServiceComplete: {
    value: 'customerServiceRequestStatusServiceComplete',
    name: `Service Request status changed to "${serviceRequestDispatchStatuses.serviceComplete.name}"`,
  },
  customerServiceRequestStatusCanceled: {
    value: 'customerServiceRequestStatusCanceled',
    name: `Service Request canceled`,
  },
  customerServiceRequestStatusClosed: {
    value: 'customerServiceRequestStatusClosed',
    name: `Service Request status changed to "${serviceRequestDispatchStatuses.closed.name}"`,
  },
  assignedServiceRequestDateReminder: {
    value: 'assignedServiceRequestDateReminder',
    name: 'Service Request due date reminder',
  },
  technicianCheckin: { value: 'technicianCheckin', name: 'Technician Check In' },
  technicianCheckout: { value: 'technicianCheckout', name: 'Technician Check Out' },
  technicianETA: { value: 'technicianETA', name: 'Technician ETA' },
};

export const escalatedSeverityNumber = 6;
export const serviceRequestServiceTypes = {
  consumables: { value: 'Consumables', name: 'Consumables' },
  corrective: { value: 'Corrective', name: 'Corrective' },
  preventive: { value: 'Preventive', name: 'Preventive' },
  other: { value: 'Other', name: 'Other' },
};

export const serviceRequestSizeOptions = {
  [ServiceRequestSizeType.Xs]: { value: ServiceRequestSizeType.Xs, name: 'XS' },
  [ServiceRequestSizeType.S]: { value: ServiceRequestSizeType.S, name: 'S' },
  [ServiceRequestSizeType.M]: { value: ServiceRequestSizeType.M, name: 'M' },
  [ServiceRequestSizeType.L]: { value: ServiceRequestSizeType.L, name: 'L' },
  [ServiceRequestSizeType.Xl]: { value: ServiceRequestSizeType.Xl, name: 'XL' },
};

export const serviceRequestPriorityOptions = {
  [ServiceRequestPriorityType.Low]: { value: ServiceRequestPriorityType.Low, name: 'Low' },
  [ServiceRequestPriorityType.Medium]: { value: ServiceRequestPriorityType.Medium, name: 'Medium' },
  [ServiceRequestPriorityType.High]: { value: ServiceRequestPriorityType.High, name: 'High' },
  [ServiceRequestPriorityType.Critical]: { value: ServiceRequestPriorityType.Critical, name: 'Critical' },
};

export const requestOriginationOptions = {
  [RequestOriginationType.AutomatedFromEmail]: { value: RequestOriginationType.AutomatedFromEmail, name: 'Email automation' },
  [RequestOriginationType.AutomatedFromQueue]: { value: RequestOriginationType.AutomatedFromQueue, name: 'TMS automation' },
  [RequestOriginationType.CustomerRequest]: { value: RequestOriginationType.CustomerRequest, name: 'Customer created' },
  [RequestOriginationType.EquipsDuringInvoicing]: { value: RequestOriginationType.EquipsDuringInvoicing, name: 'During invoicing' },
  [RequestOriginationType.EquipsOnBehalfOfCustomer]: {
    value: RequestOriginationType.EquipsOnBehalfOfCustomer,
    name: 'On behalf of customer',
  },
};

export const finalizedRequestStatuses = [
  serviceRequestDispatchStatuses.invoiced.value,
  serviceRequestDispatchStatuses.serviceComplete.value,
  serviceRequestDispatchStatuses.closed.value,
];

export const defaultServiceRequestStatus = ServiceRequestStatusType.InternalDispatch;

export const serviceRequestNoteTags = {
  description: { value: 'description', name: 'Description' },
  resolution: { value: 'resolution', name: 'Resolution' },
  internalTicketNote: { value: 'internalTicketNote' },
};

export const fileMetadataTags = {
  legacyOOSInvoice: { value: 'legacyOOSInvoice', name: 'Out of scope invoice' },
};

export const organizationBillingMethods = {
  singleSourceBilling: { value: 'singleSourceBilling', name: 'Single source billing' },
  externalBilling: { value: 'externalBilling', name: 'External billing' },
};

export const organizationBillingEmailOptions = {
  [EmailInvoicesToEnumType.AccountManager]: { value: EmailInvoicesToEnumType.AccountManager, name: 'Account manager' },
  [EmailInvoicesToEnumType.BillingContacts]: { value: EmailInvoicesToEnumType.BillingContacts, name: 'Billing contacts' },
};
export const equipmentOptionsCoverageDescriptionEnum = {
  both: { value: 'both', name: 'Both' },
  labor: { value: 'labor', name: 'Labor' },
  neither: { value: 'neither', name: 'Neither' },
  parts: { value: 'parts', name: 'Parts' },
};
export const contractStatusOptions = {
  [ContractStatusType.Inactive]: { value: ContractStatusType.Inactive, name: 'Inactive' },
  [ContractStatusType.Effective]: { value: ContractStatusType.Effective, name: 'Effective' },
  [ContractStatusType.Processing]: { value: ContractStatusType.Processing, name: 'Processing' },
  [ContractStatusType.Proposed]: { value: ContractStatusType.Proposed, name: 'Proposed' },
};

export const coverageStatuses = {
  [CoverageStatusType.Inactive]: { name: 'Inactive', value: CoverageStatusType.Inactive },
  [CoverageStatusType.Requested]: { name: 'Requested', value: CoverageStatusType.Requested },
  [CoverageStatusType.Processing]: { name: 'Equips processing', value: CoverageStatusType.Processing },
  [CoverageStatusType.Proposed]: { name: 'Proposed', value: CoverageStatusType.Proposed },
  [CoverageStatusType.Effective]: { name: 'Effective', value: CoverageStatusType.Effective },
};

export const equipmentStatusType = {
  [EquipmentStatusType.Active]: { name: 'Active', value: EquipmentStatusType.Active },
  [EquipmentStatusType.Proposed]: { name: 'Proposed', value: EquipmentStatusType.Proposed },
};

export const equipmentHealthType = {
  [EquipmentHealthType.Good]: { name: 'Good', value: EquipmentHealthType.Good },
  [EquipmentHealthType.Fair]: { name: 'Fair', value: EquipmentHealthType.Fair },
  [EquipmentHealthType.Poor]: { name: 'Poor', value: EquipmentHealthType.Poor },
};

// Intentionally only use Annually for now
export const equipsCoveragePmCadenceType = {
  [EquipsCoveragePmCadenceType.Annually]: { name: 'Annually', value: EquipsCoveragePmCadenceType.Annually },
};

export const monthsToPmEquipmentType = {
  [MonthsToPmEquipmentType.Jan]: { name: 'JAN', value: MonthsToPmEquipmentType.Jan, weight: 1 },
  [MonthsToPmEquipmentType.Feb]: { name: 'FEB', value: MonthsToPmEquipmentType.Feb, weight: 2 },
  [MonthsToPmEquipmentType.Mar]: { name: 'MAR', value: MonthsToPmEquipmentType.Mar, weight: 3 },
  [MonthsToPmEquipmentType.Apr]: { name: 'APR', value: MonthsToPmEquipmentType.Apr, weight: 4 },
  [MonthsToPmEquipmentType.May]: { name: 'MAY', value: MonthsToPmEquipmentType.May, weight: 5 },
  [MonthsToPmEquipmentType.Jun]: { name: 'JUN', value: MonthsToPmEquipmentType.Jun, weight: 6 },
  [MonthsToPmEquipmentType.Jul]: { name: 'JUL', value: MonthsToPmEquipmentType.Jul, weight: 7 },
  [MonthsToPmEquipmentType.Aug]: { name: 'AUG', value: MonthsToPmEquipmentType.Aug, weight: 8 },
  [MonthsToPmEquipmentType.Sep]: { name: 'SEP', value: MonthsToPmEquipmentType.Sep, weight: 9 },
  [MonthsToPmEquipmentType.Oct]: { name: 'OCT', value: MonthsToPmEquipmentType.Oct, weight: 10 },
  [MonthsToPmEquipmentType.Nov]: { name: 'NOV', value: MonthsToPmEquipmentType.Nov, weight: 11 },
  [MonthsToPmEquipmentType.Dec]: { name: 'DEC', value: MonthsToPmEquipmentType.Dec, weight: 12 },
};

export const coverageTypes = {
  firstDollar: { name: 'First dollar', value: 'firstDollar' },
  deductible: { name: 'Deductible', value: 'deductible' },
};

export const userTypes = {
  primary: { value: UserTagsType.Primary, name: 'Primary' },
  secondary: { value: UserTagsType.Secondary, name: 'Secondary' },
  signatory: { value: UserTagsType.Signatory, name: 'Signatory' },
  billing: { value: UserTagsType.Billing, name: 'Billing' },
  inHouseTech: { value: UserTagsType.InHouseTech, name: 'InHouseTech' },
  trustedServiceRequestSubmitter: { value: UserTagsType.TrustedServiceRequestSubmitter, name: 'TrustedServiceRequestSubmitter' },
};

export const userTypesForSGAs = {
  primary: { value: UserTagsType.Primary, name: 'Primary' },
  secondary: { value: UserTagsType.Secondary, name: 'Secondary' },
  signatory: { value: UserTagsType.Signatory, name: 'Signatory' },
  billing: { value: UserTagsType.Billing, name: 'Billing' },
  inHouseTech: { value: UserTagsType.InHouseTech, name: 'InHouseTech' },
  auditor: { value: UserTagsType.Auditor, name: 'Auditor' },
  trustedServiceRequestSubmitter: { value: UserTagsType.TrustedServiceRequestSubmitter, name: 'TrustedServiceRequestSubmitter' },
  aas: { value: UserTagsType.Aas, name: 'AAS' },
  workOrderManagement: { value: UserTagsType.WorkOrderManagement, name: 'WorkOrderManagement' },
  coverage: { value: UserTagsType.Coverage, name: 'Coverage' },
  aasAdmin: { value: UserTagsType.AasAdmin, name: 'AASAdmin' },
  aasPaymentTeam: { value: UserTagsType.AasPaymentTeam, name: 'AASPaymentTeam' },
  aasUserAdministrator: { value: UserTagsType.AasUserAdministrator, name: 'AASUserAdministrator' },
  aasPilot: { value: UserTagsType.AasPilot, name: 'AASPilot' },
  aasExternalDispatch: { value: UserTagsType.AasExternalDispatch, name: 'AASExternalDispatch' },
};

export const userTypesForAAS = {
  aasPaymentTeam: { value: UserTagsType.AasPaymentTeam, name: 'AASPaymentTeam' },
  aasUserAdministrator: { value: UserTagsType.AasUserAdministrator, name: 'AASUserAdministrator' },
  aasPilot: { value: UserTagsType.AasPilot, name: 'AASPilot' },
  aasExternalDispatch: { value: UserTagsType.AasExternalDispatch, name: 'AASExternalDispatch' },
};

export const featureFlagTypes = {
  customerInvoiceSubmission: { value: UserFeatureFlagEnum.CustomerInvoiceSubmission, name: 'CustomerInvoiceSubmission' },
  incidentsPage: { value: UserFeatureFlagEnum.IncidentsPage, name: 'IncidentsPage' },
  preventativeMaintenance: { value: UserFeatureFlagEnum.PreventativeMaintenance, name: 'PreventativeMaintenance' },
};

export const contractTypes = {
  [ContractTypeEnum.FirstDollar]: { value: ContractTypeEnum.FirstDollar, name: 'First dollar' },
  [ContractTypeEnum.AdminOnly]: { value: ContractTypeEnum.AdminOnly, name: 'Admin only' },
  [ContractTypeEnum.Deductible]: { value: ContractTypeEnum.Deductible, name: 'Deductible' },
  [ContractTypeEnum.Hybrid]: { value: ContractTypeEnum.Hybrid, name: 'Hybrid' },
};

export const subscriptionStatusOptions = {
  [SubscriptionStatus.Inactive]: { value: SubscriptionStatus.Inactive, name: 'Inactive' },
  [SubscriptionStatus.Effective]: { value: SubscriptionStatus.Effective, name: 'Effective' },
  [SubscriptionStatus.Processing]: { value: SubscriptionStatus.Processing, name: 'Processing' },
  [SubscriptionStatus.Proposed]: { value: SubscriptionStatus.Proposed, name: 'Proposed' },
  [SubscriptionStatus.Requested]: { value: SubscriptionStatus.Requested, name: 'Requested' },
};

export const billingPeriodOptions = {
  [BillingPeriod.Daily]: { value: BillingPeriod.Daily, name: 'Daily' },
  [BillingPeriod.Monthly]: { value: BillingPeriod.Monthly, name: 'Monthly' },
  [BillingPeriod.Once]: { value: BillingPeriod.Once, name: 'Once' },
  [BillingPeriod.Quarterly]: { value: BillingPeriod.Quarterly, name: 'Quarterly' },
  [BillingPeriod.Yearly]: { value: BillingPeriod.Yearly, name: 'Yearly' },
};

export const subscriptionTypeOptions = {
  [SubscriptionType.ManagedServices]: { value: SubscriptionType.ManagedServices, name: 'Managed services' },
  [SubscriptionType.CookManagedServices]: { value: SubscriptionType.CookManagedServices, name: 'Cook managed services' },
  [SubscriptionType.ServiceDispatch]: { value: SubscriptionType.ServiceDispatch, name: 'Service dispatch' },
};

export const fixMultiselectOptions = [
  { label: 'Cash Out', value: IncidentFixesEnum.CashOut },
  { label: 'Divert Bin Full', value: IncidentFixesEnum.DivertBinFull },
  { label: 'Lost Comms', value: IncidentFixesEnum.LostComms },
  { label: 'Self Resolved', value: IncidentFixesEnum.SelfResolved },
  { label: 'Supervisor Mode', value: IncidentFixesEnum.SupervisorMode },
  { label: 'Other', value: IncidentFixesEnum.Other },
];

export const actionMultiselectOptions = [
  { label: 'Card Reader Reboot', value: IncidentActionsEnum.CardReaderReboot },
  { label: 'Journal Reboot', value: IncidentActionsEnum.JournalReboot },
  { label: 'Electronic Journal Review', value: IncidentActionsEnum.ElectronicJournalReview },
  { label: 'Machine Reboot', value: IncidentActionsEnum.MachineReboot },
  { label: 'Pinpad Reboot', value: IncidentActionsEnum.PinpadReboot },
  { label: 'Receipt Reboot', value: IncidentActionsEnum.ReceiptReboot },
  { label: 'Dispenser Reboot', value: IncidentActionsEnum.DispenserReboot },
  { label: 'Lost Comms Review', value: IncidentActionsEnum.LostCommsReview },
];

export const notificationMethods = {
  [NotificationMethodEnum.Email]: { value: NotificationMethodEnum.Email, name: 'Email' },
  [NotificationMethodEnum.Sms]: { value: NotificationMethodEnum.Sms, name: 'SMS' },
};

export const serviceContractStatusTypes = {
  [ServiceContractStatus.Effective]: { value: 'effective', name: 'Effective' },
  [ServiceContractStatus.Inactive]: { value: 'inactive', name: 'Inactive' },
  [ServiceContractStatus.Proposed]: { value: 'proposed', name: 'Proposed' },
};

export const checklistStepTypes = {
  [ChecklistStepType.Checkbox]: { value: 'checkbox', name: 'Checkbox' },
  [ChecklistStepType.Text]: { value: 'text', name: 'Free Form Text' },
  [ChecklistStepType.Single]: { value: 'single', name: 'Single Select Dropdown' },
  [ChecklistStepType.Multiple]: { value: 'multiple', name: 'Multiselect Dropdown' },
};

export const organizationTiers = {
  [OrganizationTier.Gold]: { value: OrganizationTier.Gold, name: 'Gold' },
  [OrganizationTier.Silver]: { value: OrganizationTier.Silver, name: 'Silver' },
};

export const servicePowerEquipmentManufacturers = {
  ['ABL']: { value: 'ABL', name: 'ABLESOFT' },
  ['ABS']: { value: 'ABS', name: 'ABSOCOLD' },
  ['ACH']: { value: 'ACH', name: 'ACHEIVER' },
  ['ACM']: { value: 'ACM', name: 'ACME' },
  ['ADC']: { value: 'ADC', name: 'ADC' },
  ['ADI']: { value: 'ADI', name: 'ADI' },
  ['ADM']: { value: 'ADM', name: 'ADMIRAL' },
  ['ADR']: { value: 'ADR', name: 'ADMOR' },
  ['ADV']: { value: 'ADV', name: 'ADVENT' },
  ['AEG']: { value: 'AEG', name: 'AEGIS' },
  ['AFC']: { value: 'AFC', name: 'AFCO' },
  ['AGA']: { value: 'AGA', name: 'AGA' },
  ['AHM']: { value: 'AHM', name: 'AHM ACOUSTICS CORP' },
  ['AIK']: { value: 'AIK', name: 'AIKO' },
  ['AIR']: { value: 'AIR', name: 'AIR TEMP' },
  ['AIC']: { value: 'AIC', name: 'AIRCAP' },
  ['ARS']: { value: 'ARS', name: 'AIREASE' },
  ['ARP']: { value: 'ARP', name: 'AIRPRO' },
  ['ARQ']: { value: 'ARQ', name: 'AIRQUEST' },
  ['AWY']: { value: 'AWY', name: 'AIRWAY' },
  ['AIT']: { value: 'AIT', name: 'AITECH INTERNATIONAL' },
  ['AIW']: { value: 'AIW', name: 'AIWA' },
  ['ALA']: { value: 'ALA', name: 'ALARON' },
  ['ALU']: { value: 'ALU', name: 'ALFRESCO' },
  ['ALE']: { value: 'ALE', name: 'ALLEGRO CORPORATION' },
  ['ALM']: { value: 'ALM', name: 'ALLNET' },
  ['ATB']: { value: 'ATB', name: 'ALLTEL' },
  ['AMA']: { value: 'AMA', name: 'AMANA' },
  ['AMK']: { value: 'AMK', name: 'AMARK' },
  ['AMB']: { value: 'AMB', name: 'AMBASSADOR' },
  ['ABR']: { value: 'ABR', name: 'AMBRA' },
  ['AMM']: { value: 'AMM', name: 'AMC' },
  ['AOI']: { value: 'AOI', name: 'AMCOR' },
  ['ACN']: { value: 'ACN', name: 'AMERICA ACTION' },
  ['ANI']: { value: 'ANI', name: 'AMERICAN' },
  ['AMD']: { value: 'AMD', name: 'AMERICAN COVERS, INC' },
  ['AMC']: { value: 'AMC', name: 'AMERICAN CROWN' },
  ['AME']: { value: 'AME', name: 'AMERICAN ENERGY' },
  ['AMF']: { value: 'AMF', name: 'AMERICAN INTERNATION' },
  ['ASH']: { value: 'ASH', name: 'AMERICAN SOFTWARE &' },
  ['AMS']: { value: 'AMS', name: 'AMERICAN STANDARD' },
  ['AED']: { value: 'AED', name: 'AMERICANA' },
  ['ANJ']: { value: 'ANJ', name: 'AMERIQUEST TECHNOLOG' },
  ['AMW']: { value: 'AMW', name: 'AMERIWOOD' },
  ['AIA']: { value: 'AIA', name: 'AMICA' },
  ['AAB']: { value: 'AAB', name: 'AMICO' },
  ['AMG']: { value: 'AMG', name: 'AMIGA' },
  ['AMI']: { value: 'AMI', name: 'AMIL' },
  ['AMO']: { value: 'AMO', name: 'AMOK' },
  ['AMP']: { value: 'AMP', name: 'AMPRO' },
  ['AMN']: { value: 'AMN', name: 'AMTRON' },
  ['ANA']: { value: 'ANA', name: 'ANCHOR HOCKING' },
  ['AND']: { value: 'AND', name: 'ANDI' },
  ['ANS']: { value: 'ANS', name: 'ANSI' },
  ['AOC']: { value: 'AOC', name: 'AOC' },
  ['APC']: { value: 'APC', name: 'APCOLD' },
  ['AAP']: { value: 'AAP', name: 'APOLLO' },
  ['APO']: { value: 'APO', name: 'APPOINT' },
  ['APR']: { value: 'APR', name: 'APRILAIRE' },
  ['ARA']: { value: 'ARA', name: 'ARA MANU' },
  ['ARE']: { value: 'ARE', name: 'ARCOAIRE' },
  ['ARC']: { value: 'ARC', name: 'ARCTURNS' },
  ['ARU']: { value: 'ARU', name: 'ARCUS' },
  ['ARD']: { value: 'ARD', name: 'ARES GLOBAL' },
  ['ARG']: { value: 'ARG', name: 'ARGOSY' },
  ['AR3']: { value: 'AR3', name: 'ARIETTA' },
  ['ARB']: { value: 'ARB', name: 'ARISTA' },
  ['ARI']: { value: 'ARI', name: 'ARISTON' },
  ['AMR']: { value: 'AMR', name: 'ARM' },
  ['ARR']: { value: 'ARR', name: 'ARROWHEAD DIST INC' },
  ['ARV']: { value: 'ARV', name: 'ARVIN' },
  ['ASI']: { value: 'ASI', name: 'ASCII' },
  ['ASCO']: { value: 'ASCO', name: 'ASCO APPLIANCES' },
  ['ASE']: { value: 'ASE', name: 'ASEA' },
  ['ASK']: { value: 'ASK', name: 'ASKO' },
  ['ATS']: { value: 'ATS', name: 'AST' },
  ['ASD']: { value: 'ASD', name: 'ASTAR PRODUCTS' },
  ['AST']: { value: 'AST', name: 'ASTRA' },
  ['ASM']: { value: 'ASM', name: 'ASYMETRIX' },
  ['ATA']: { value: 'ATA', name: 'ATARI' },
  ['ATH']: { value: 'ATH', name: 'ATHENS' },
  ['ATI']: { value: 'ATI', name: 'ATI' },
  ['AUK']: { value: 'AUK', name: 'ATLANTIS' },
  ['AUB']: { value: 'AUB', name: 'AUBREY' },
  ['AUD']: { value: 'AUD', name: 'AUDIO SONIC' },
  ['ADT']: { value: 'ADT', name: 'AUDIO TECH' },
  ['ASC']: { value: 'ASC', name: 'AUDIOSCAN' },
  ['AVI']: { value: 'AVI', name: 'AVALON HILL' },
  ['AVA']: { value: 'AVA', name: 'AVANTI' },
  ['AVB']: { value: 'AVB', name: 'AVB' },
  ['AVE']: { value: 'AVE', name: 'AVENTURA' },
  ['AVR']: { value: 'AVR', name: 'AVERY' },
  ['AVT']: { value: 'AVT', name: 'AVONTI' },
  ['B&O']: { value: 'B&O', name: 'B&O' },
  ['BAG']: { value: 'BAG', name: 'BADGER' },
  ['BAB']: { value: 'BAB', name: 'BAG END' },
  ['BAF']: { value: 'BAF', name: 'BANDAI' },
  ['BAN']: { value: 'BAN', name: 'BANDIX' },
  ['BAD']: { value: 'BAD', name: 'BANG' },
  ['BAR']: { value: 'BAR', name: 'BARD' },
  ['BAS']: { value: 'BAS', name: 'BASF' },
  ['BAJ']: { value: 'BAJ', name: 'BATES' },
  ['BEA']: { value: 'BEA', name: 'BEAUMARK' },
  ['BEC']: { value: 'BEC', name: 'BECKER' },
  ['BE']: { value: 'BE', name: 'BEKO' },
  ['BEI']: { value: 'BEI', name: 'BELDEN' },
  ['BEJ']: { value: 'BEJ', name: 'BELL' },
  ['BSC']: { value: 'BSC', name: 'BELL SOUTH CELLULAR' },
  ['BET']: { value: 'BET', name: 'BELTRONICS' },
  ['BEM']: { value: 'BEM', name: 'BEMIS' },
  ['BEN']: { value: 'BEN', name: 'BENDIX' },
  ['BER']: { value: 'BER', name: 'BERGEN' },
  ['BZZ']: { value: 'BZZ', name: 'BERTAZZONI' },
  ['BEF']: { value: 'BEF', name: 'BESSER' },
  ['BEO']: { value: 'BEO', name: 'BEST' },
  ['BES']: { value: 'BES', name: 'BEST HOODS' },
  ['BED']: { value: 'BED', name: 'BESTAR, INC' },
  ['BIB']: { value: 'BIB', name: 'BIB AMERICA' },
  ['BIC']: { value: 'BIC', name: 'BIC' },
  ['BIO']: { value: 'BIO', name: 'BIONAIRE' },
  ['B&D']: { value: 'B&D', name: 'BLACK & DECKER' },
  ['BLA']: { value: 'BLA', name: 'BLACKSTONE' },
  ['BLC']: { value: 'BLC', name: 'BLANCO' },
  ['BLU']: { value: 'BLU', name: 'BLAUPUNKT' },
  ['BLO']: { value: 'BLO', name: 'BLODJET' },
  ['BLOMBE']: { value: 'BLOMBE', name: 'BLOMBERG' },
  ['BLL']: { value: 'BLL', name: 'BLUE STAR' },
  ['BMR']: { value: 'BMR', name: 'BLUEMOON' },
  ['BOC']: { value: 'BOC', name: 'BOCA' },
  ['BOG']: { value: 'BOG', name: 'BOGEN' },
  ['BOH']: { value: 'BOH', name: 'BOHSEI' },
  ['BOM']: { value: 'BOM', name: 'BOMAN' },
  ['BOE']: { value: 'BOE', name: 'BOMPANI' },
  ['BOB']: { value: 'BOB', name: 'BONAIRE' },
  ['BCH']: { value: 'BCH', name: 'BOSCH' },
  ['BOS']: { value: 'BOS', name: 'BOSE' },
  ['BSS']: { value: 'BSS', name: 'BOSS' },
  ['BRA']: { value: 'BRA', name: 'BRADFORD' },
  ['BWH']: { value: 'BWH', name: 'BRADFORD WHITE' },
  ['BRW']: { value: 'BRW', name: 'BRAINWORKS' },
  ['BRI']: { value: 'BRI', name: 'BRAND' },
  ['BRN']: { value: 'BRN', name: 'BRAUN' },
  ['BRE']: { value: 'BRE', name: 'BRENTWOOD' },
  ['BRS']: { value: 'BRS', name: 'BRIGGS & STRATTON' },
  ['BRB']: { value: 'BRB', name: 'BRINKMAN' },
  ['BRL']: { value: 'BRL', name: 'BRITA' },
  ['BRP']: { value: 'BRP', name: 'BRITE POINT' },
  ['BRQ']: { value: 'BRQ', name: 'BROAD' },
  ['BRD']: { value: 'BRD', name: 'BROADMOOR' },
  ['BRO']: { value: 'BRO', name: 'BROAN' },
  ['BKW']: { value: 'BKW', name: 'BROCKWOOD' },
  ['BTH']: { value: 'BTH', name: 'BROTHER' },
  ['BWN']: { value: 'BWN', name: 'BROWN' },
  ['BRY']: { value: 'BRY', name: 'BRYANT' },
  ['BSR']: { value: 'BSR', name: 'BSR' },
  ['BUD']: { value: 'BUD', name: 'BUDMAN' },
  ['BTA']: { value: 'BTA', name: 'BUFFALO TECHNOLOGY' },
  ['BUB']: { value: 'BUB', name: 'BUSH' },
  ['BUS']: { value: 'BUS', name: 'BUSHNELL' },
  ['CAE']: { value: 'CAE', name: 'CAEDA' },
  ['CALF']: { value: 'CALF', name: 'CAL FLAME' },
  ['CSP']: { value: 'CSP', name: 'CAL SPAS' },
  ['CAJ']: { value: 'CAJ', name: 'CALDERA' },
  ['CLX']: { value: 'CLX', name: 'CALIX' },
  ['CAL']: { value: 'CAL', name: 'CALORIC' },
  ['CAI']: { value: 'CAI', name: 'CAMERICA' },
  ['CAN']: { value: 'CAN', name: 'CANON' },
  ['CAP']: { value: 'CAP', name: 'CAPEHART' },
  ['CPL']: { value: 'CPL', name: 'CAPITAL' },
  ['CRM']: { value: 'CRM', name: 'CAPRICORN' },
  ['CRD']: { value: 'CRD', name: 'CARDINAL' },
  ['CAX']: { value: 'CAX', name: 'CARINA' },
  ['CRR']: { value: 'CRR', name: 'CARRIER' },
  ['CAR']: { value: 'CAR', name: 'CARVER' },
  ['CAS']: { value: 'CAS', name: 'CASIO' },
  ['CAA']: { value: 'CAA', name: 'CATALINA INDUSTRIES' },
  ['CAT']: { value: 'CAT', name: 'CATSKILL CRAFTSMEN' },
  ['CDC']: { value: 'CDC', name: 'CDMI' },
  ['BST']: { value: 'BST', name: 'CELLULAR ONE BOSTO' },
  ['TAM']: { value: 'TAM', name: 'CELLULAR ONE TAMPA' },
  ['MNP']: { value: 'MNP', name: 'CELLULAR ONE/MINNEAP' },
  ['CEG']: { value: 'CEG', name: 'CENDYNE' },
  ['ACS']: { value: 'ACS', name: 'CENTRAL AIR CONDITIO' },
  ['CEN']: { value: 'CEN', name: 'CENTURY' },
  ['CER']: { value: 'CER', name: 'CERWIN VEGA' },
  ['CHA']: { value: 'CHA', name: 'CHAMBERS' },
  ['CHN']: { value: 'CHN', name: 'CHAMPION' },
  ['CHB']: { value: 'CHB', name: 'CHAR BROIL' },
  ['CAF']: { value: 'CAF', name: 'CHARISMA' },
  ['CHK']: { value: 'CHK', name: 'CHARMGLO' },
  ['CHT']: { value: 'CHT', name: 'CHATEAU' },
  ['CHE']: { value: 'CHE', name: 'CHEYENE' },
  ['CHM']: { value: 'CHM', name: 'CHROMONITE' },
  ['CHR']: { value: 'CHR', name: 'CHRYSLER' },
  ['CIC']: { value: 'CIC', name: 'CICENA' },
  ['CTN']: { value: 'CTN', name: 'CITATION' },
  ['CTK']: { value: 'CTK', name: 'CITEK' },
  ['CIT']: { value: 'CIT', name: 'CITIZEN' },
  ['CLA']: { value: 'CLA', name: 'CLARION' },
  ['CLK']: { value: 'CLK', name: 'CLARKE' },
  ['CLL']: { value: 'CLL', name: 'CLASSIC' },
  ['CLI']: { value: 'CLI', name: 'CLIMATROL' },
  ['COB']: { value: 'COB', name: 'COBRA' },
  ['COY']: { value: 'COY', name: 'COBY' },
  ['CCR']: { value: 'CCR', name: 'COLD CHILLIN' },
  ['COL']: { value: 'COL', name: 'COLDEX' },
  ['CDS']: { value: 'CDS', name: 'COLDSPOT' },
  ['CDJ']: { value: 'CDJ', name: 'COLDTECH' },
  ['CLE']: { value: 'CLE', name: 'COLEMAN' },
  ['CLR']: { value: 'CLR', name: 'COLORADO MEMORY SYS' },
  ['COW']: { value: 'COW', name: 'COM LINE' },
  ['COH']: { value: 'COH', name: 'COMFORT AIRE' },
  ['CFM']: { value: 'CFM', name: 'COMFORT MAKER' },
  ['CMD']: { value: 'CMD', name: 'COMMODORE' },
  ['CMP']: { value: 'CMP', name: 'COMPACT' },
  ['CPQ']: { value: 'CPQ', name: 'COMPAQ' },
  ['COM']: { value: 'COM', name: 'COMPUSONIC' },
  ['CAC']: { value: 'CAC', name: 'CON AIR CUSINE' },
  ['CNA']: { value: 'CNA', name: 'CONAIR' },
  ['CCD']: { value: 'CCD', name: 'CONCORD' },
  ['CNC']: { value: 'CNC', name: 'CONIC' },
  ['CTA']: { value: 'CTA', name: 'CONNECTICUT AIR COND' },
  ['CNR']: { value: 'CNR', name: 'CONNER' },
  ['COF']: { value: 'COF', name: 'CONSOLE REFRIGERATIO' },
  ['CON']: { value: 'CON', name: 'CONTEC' },
  ['CNL']: { value: 'CNL', name: 'CONTINENTAL' },
  ['COO']: { value: 'COO', name: 'COOLERATOR' },
  ['COR']: { value: 'COR', name: 'CORNING' },
  ['CFE']: { value: 'CFE', name: 'CORNUE FE' },
  ['CNO']: { value: 'CNO', name: 'CORONA' },
  ['CDO']: { value: 'CDO', name: 'CORONADO' },
  ['COD']: { value: 'COD', name: 'COSCO' },
  ['COS']: { value: 'COS', name: 'COSMO' },

  ['MCT']: { value: 'MCT', name: 'COSS (MICROSOFT)' },
  ['CRF']: { value: 'CRF', name: 'CRAFTSMAN' },
  ['CRA']: { value: 'CRA', name: 'CRAIG' },
  ['CNE']: { value: 'CNE', name: 'CRANE' },
  ['CDA']: { value: 'CDA', name: 'CREDA' },
  ['CRE']: { value: 'CRE', name: 'CREST' },
  ['CBY']: { value: 'CBY', name: 'CROSBY' },
  ['CRO']: { value: 'CRO', name: 'CROSLEY' },
  ['CRN']: { value: 'CRN', name: 'CROWN' },
  ['CRB']: { value: 'CRB', name: 'CROWN ARUBA' },
  ['CRS']: { value: 'CRS', name: 'CRYSTAL SCAN' },
  ['CXT']: { value: 'CXT', name: 'CTX' },
  ['CUR']: { value: 'CUR', name: 'CURLY' },
  ['CRW']: { value: 'CRW', name: 'CURTIS MANUFACTURING' },
  ['CTM']: { value: 'CTM', name: 'CURTIS MATHIS' },
  ['CYE']: { value: 'CYE', name: 'CYBERMEDIA' },
  ['DAC']: { value: 'DAC', name: 'DACOR' },
  ['DAA']: { value: 'DAA', name: 'DACOR A' },
  ['DAW']: { value: 'DAW', name: 'DACWOOD ELECTRONIC' },
  ['DAE']: { value: 'DAE', name: 'DAEWOO ELECTRONICS' },
  ['DAK']: { value: 'DAK', name: 'DAKIN' },
  ['DAL']: { value: 'DAL', name: 'DALITE' },
  ['DNB']: { value: 'DNB', name: 'DANBY' },
  ['DAN']: { value: 'DAN', name: 'DANBY PRODUCTIONS IN' },
  ['DLA']: { value: 'DLA', name: 'DAVID LEVY COMPANY' },
  ['DAV']: { value: 'DAV', name: 'DAVIDSON' },
  ['D&N']: { value: 'D&N', name: 'DAY & NIGHT' },
  ['DLR']: { value: 'DLR', name: 'DAYLOR' },
  ['DAY']: { value: 'DAY', name: 'DAYTON' },
  ['DTN']: { value: 'DTN', name: 'DAYTRON' },
  ['DBX']: { value: 'DBX', name: 'DBX' },
  ['DCS']: { value: 'DCS', name: 'DCS' },
  ['DEE']: { value: 'DEE', name: 'DE' },
  ['DEC']: { value: 'DEC', name: 'DEC' },
  ['DEF']: { value: 'DEF', name: 'DEFIANCE' },
  ['DLC']: { value: 'DLC', name: 'DELCO' },
  ['DMO']: { value: 'DMO', name: 'DELMONICO' },
  ['DLO']: { value: 'DLO', name: 'DELONGHI' },
  ['DLG']: { value: 'DLG', name: 'DELONGI AMERICA CORP' },
  ['DEL']: { value: 'DEL', name: 'DELTA' },
  ['DEA']: { value: 'DEA', name: 'DELTA ASSOCIATES' },
  ['DEN']: { value: 'DEN', name: 'DENON' },
  ['DEH']: { value: 'DEH', name: 'DESA' },
  ['DES']: { value: 'DES', name: 'DESIGN ACCOUSTIC' },
  ['DEW']: { value: 'DEW', name: 'DEWALT' },
  ['DFI']: { value: 'DFI', name: 'DFI' },
  ['DIA']: { value: 'DIA', name: 'DIAMOND MULTIMEDIA' },
  ['DOD']: { value: 'DOD', name: 'DIGITAL LIFESTYLE OU' },
  ['DIT']: { value: 'DIT', name: 'DIGITAL PERSONA, INC' },
  ['DSL']: { value: 'DSL', name: 'DIGITAL SOUNDS LABS' },
  ['DIN']: { value: 'DIN', name: 'DINATECH' },
  ['DVL']: { value: 'DVL', name: 'DIRT DEVIL' },
  ['DCE']: { value: 'DCE', name: 'DISCOVERY PRODUCTS' },
  ['DSC']: { value: 'DSC', name: 'DISOVERY MUSIC' },
  ['DNA']: { value: 'DNA', name: 'DISTRIBUTION NORTH A' },
  ['DVI']: { value: 'DVI', name: 'DIVA' },
  ['DIU']: { value: 'DIU', name: 'DIVX' },
  ['DOM']: { value: 'DOM', name: 'DOMETIC' },
  ['DOU']: { value: 'DOU', name: 'DOUGLAS FURNITURE CO' },
  ['DOV']: { value: 'DOV', name: 'DOVICO' },
  ['DRA']: { value: 'DRA', name: 'DRAPER' },
  ['DRD']: { value: 'DRD', name: 'DREAM' },
  ['DSI']: { value: 'DSI', name: 'DSI TAYLOR ELECTRIC' },
  ['DSD']: { value: 'DSD', name: 'DSI TOYS, INC' },
  ['DUA']: { value: 'DUA', name: 'DUAL' },
  ['DCN']: { value: 'DCN', name: 'DUCANE' },
  ['DUC']: { value: 'DUC', name: 'DUCH' },
  ['DUM']: { value: 'DUM', name: 'DUMONT' },
  ['DUR']: { value: 'DUR', name: 'DURACELL' },
  ['DCF']: { value: 'DCF', name: 'DYNACRAFT' },
  ['DCA']: { value: 'DCA', name: 'DYNAMIC CONTROL' },
  ['DYA']: { value: 'DYA', name: 'DYNAMIC COOKING SYST' },
  ['DYP']: { value: 'DYP', name: 'DYNAMIC PATHWAYS' },
  ['DYS']: { value: 'DYS', name: 'DYNASTY' },
  ['DYN']: { value: 'DYN', name: 'DYNATECH' },
  ['DYW']: { value: 'DYW', name: 'DYNAWARE' },
  ['DYF']: { value: 'DYF', name: 'DYSON' },
  ['EM']: { value: 'EM', name: 'E MACHINES' },
  ['EWC']: { value: 'EWC', name: 'E.W.C.' },
  ['EAG']: { value: 'EAG', name: 'EAGLE' },
  ['EWA']: { value: 'EWA', name: 'EAST WEST AMERICA' },
  ['EAS']: { value: 'EAS', name: 'EASY' },
  ['EAW']: { value: 'EAW', name: 'EAW' },
  ['EBC']: { value: 'EBC', name: 'EBCO' },
  ['ECB']: { value: 'ECB', name: 'ECHO' },
  ['ECC']: { value: 'ECC', name: 'ECLIPSE' },
  ['EDI']: { value: 'EDI', name: 'EDISON' },
  ['ELA']: { value: 'ELA', name: 'ELAN' },
  ['ELE']: { value: 'ELE', name: 'ELECTRO BRAND' },
  ['ELV']: { value: 'ELV', name: 'ELECTRO VOICE' },
  ['ELL']: { value: 'ELL', name: 'ELECTROGRAPH' },
  ['ETH']: { value: 'ETH', name: 'ELECTROHOME' },
  ['ELX']: { value: 'ELX', name: 'ELECTROLUX' },
  ['EWI']: { value: 'EWI', name: 'ELECTRONICS WHOLESAL' },
  ['ELP']: { value: 'ELP', name: 'ELECTROPHONICS' },
  ['EAA']: { value: 'EAA', name: 'ELEKTRA ASYLUM' },
  ['EL3']: { value: 'EL3', name: 'ELICA' },
  ['ECN']: { value: 'ECN', name: 'ELICA COLLECTION' },
  ['ELK']: { value: 'ELK', name: 'ELKAY' },
  ['ELM']: { value: 'ELM', name: 'ELMIRA' },
  ['EMC']: { value: 'EMC', name: 'EMC' },
  ['EME']: { value: 'EME', name: 'EMERSON' },
  ['EMS']: { value: 'EMS', name: 'EMESS LIGHTING' },
  ['EMI']: { value: 'EMI', name: 'EMI' },
  ['EC']: { value: 'EC', name: 'ENCORE' },
  ['ENT']: { value: 'ENT', name: 'ENTERPRISE' },
  ['ENH']: { value: 'ENH', name: 'ENVIRACAIRE' },
  ['ENI']: { value: 'ENI', name: "ENVIROGEN INT'L" },
  ['ENV']: { value: 'ENV', name: 'ENVISION' },
  ['EPI']: { value: 'EPI', name: 'EPI' },
  ['EPD']: { value: 'EPD', name: 'EPICURE' },
  ['EPS']: { value: 'EPS', name: 'EPSON' },
  ['EQT']: { value: 'EQT', name: 'EQUATOR' },
  ['EQU']: { value: 'EQU', name: 'EQUUS' },
  ['ESQ']: { value: 'ESQ', name: 'ESQUIRE IMPORT' },
  ['ESE']: { value: 'ESE', name: 'ESSELTE PENDAFLEX' },
  ['ESS']: { value: 'ESS', name: 'ESSEX' },
  ['EST']: { value: 'EST', name: 'ESTATE' },
  ['ETO']: { value: 'ETO', name: 'ETON' },
  ['EUB']: { value: 'EUB', name: 'EUBANKS' },
  ['EUD']: { value: 'EUD', name: 'EUDORA' },
  ['EUR']: { value: 'EUR', name: 'EURGOLD' },
  ['EUC']: { value: 'EUC', name: 'EUROTECH' },
  ['EVS']: { value: 'EVS', name: 'EVERSAFE' },
  ['EVE']: { value: 'EVE', name: 'EVERSON' },
  ['EVT']: { value: 'EVT', name: 'EVERSTAR' },
  ['EWV']: { value: 'EWV', name: 'EWAVE' },
  ['EXC']: { value: 'EXC', name: 'EXCELLENCE' },
  ['EX']: { value: 'EX', name: 'EXMARK' },
  ['EXS']: { value: 'EXS', name: 'EXPLORER' },
  ['EXP']: { value: 'EXP', name: 'EXPLORER COMMUNICATI' },
  ['EXT']: { value: 'EXT', name: 'EXPO TECH' },
  ['FAD']: { value: 'FAD', name: 'FABER' },
  ['FAC']: { value: 'FAC', name: 'FABOR' },
  ['FAG']: { value: 'FAG', name: 'FAGOR' },
  ['FAI']: { value: 'FAI', name: 'FAIP AMERICA' },
  ['FAL']: { value: 'FAL', name: 'FALCON' },
  ['FTM']: { value: 'FTM', name: 'FANTOM' },
  ['FAB']: { value: 'FAB', name: 'FARBER' },
  ['FAR']: { value: 'FAR', name: 'FARGO' },
  ['FAS']: { value: 'FAS', name: 'FASAR' },
  ['FCO']: { value: 'FCO', name: 'FCI HOME APPLIANCES' },
  ['FEA']: { value: 'FEA', name: 'FEDDERS' },
  ['FEL']: { value: 'FEL', name: 'FELLOWS' },
  ['FIE']: { value: 'FIE', name: 'FIESTA' },
  ['FIS']: { value: 'FIS', name: 'FISHER' },
  ['FPD']: { value: 'FPD', name: 'FISHER PAYKEL' },
  ['FPR']: { value: 'FPR', name: 'FISHER PRICE' },
  ['FIA']: { value: 'FIA', name: 'FITNESS QUEST' },
  ['FIT']: { value: 'FIT', name: 'FITNESS SOLUTIONS IN' },
  ['FIV']: { value: 'FIV', name: 'FIVE STAR' },
  ['FLA']: { value: 'FLA', name: 'FLAIR DESIGNS LTD' },
  ['FLO']: { value: 'FLO', name: 'FLORENCE' },
  ['FLE']: { value: 'FLE', name: 'FLOTEC' },
  ['FOD']: { value: 'FOD', name: 'FOCAL CAR' },
  ['FOR']: { value: 'FOR', name: 'FOREMOST' },
  ['FRF']: { value: 'FRF', name: 'FORMGEN' },
  ['FRN']: { value: 'FRN', name: 'FRANKE' },
  ['FRA']: { value: 'FRA', name: 'FRANKLIN' },
  ['FRK']: { value: 'FRK', name: 'FRANKLIN GOODMAN' },
  ['FRE']: { value: 'FRE', name: 'FREYVEN' },
  ['FRR']: { value: 'FRR', name: 'FRIEDER, INC' },
  ['FRI']: { value: 'FRI', name: 'FRIEDRICH' },
  ['FRT']: { value: 'FRT', name: 'FRIGETTE' },
  ['FRG']: { value: 'FRG', name: 'FRIGIDAIRE' },
  ['FRD']: { value: 'FRD', name: 'FRIGO DESIGN, INC.' },
  ['FRH']: { value: 'FRH', name: 'FRONT ROW ENTERTAINM' },
  ['FRO']: { value: 'FRO', name: 'FRONTEC' },
  ['FRB']: { value: 'FRB', name: 'FRONTIER RECORDS' },
  ['FRQ']: { value: 'FRQ', name: 'FROST QUEEN' },
  ['FRM']: { value: 'FRM', name: 'FROSTMAN' },
  ['FUJ']: { value: 'FUJ', name: 'FUJI' },
  ['FUI']: { value: 'FUI', name: 'FUJITSU' },
  ['FUN']: { value: 'FUN', name: 'FUNAI' },
  ['FUS']: { value: 'FUS', name: 'FUSITSUTEN' },
  ['FUT']: { value: 'FUT', name: 'FUTURE TECH' },
  ['GVX']: { value: 'GVX', name: 'G VOX' },
  ['GMS']: { value: 'GMS', name: 'G.M.S.' },
  ['GAF']: { value: 'GAF', name: 'GAFFERS STATLERS' },
  ['GAC']: { value: 'GAC', name: 'GALLS CAR' },
  ['GAL']: { value: 'GAL', name: 'GALOOB TOYS, INC' },
  ['GAM']: { value: 'GAM', name: 'GAMETEK' },
  ['GAN']: { value: 'GAN', name: 'GAND' },
  ['GRL']: { value: 'GRL', name: 'GARLAND' },
  ['GIC']: { value: 'GIC', name: 'GARRARD INT CORP' },
  ['GAR']: { value: 'GAR', name: 'GARRISON' },
  ['GAS']: { value: 'GAS', name: 'GAS' },
  ['GSM']: { value: 'GSM', name: 'GAS MIZER' },
  ['GEP']: { value: 'GEP', name: 'GATES ENERGY PRODUCT' },
  ['GCH']: { value: 'GCH', name: 'GCH SYSTEMS' },
  ['GEB']: { value: 'GEB', name: 'GE BROWN' },
  ['GEF']: { value: 'GEF', name: 'GEFFEN RECORDS' },
  ['GEQ']: { value: 'GEQ', name: 'GEM' },
  ['GEM']: { value: 'GEM', name: 'GEMINI INDUSTRIES' },
  ['GML']: { value: 'GML', name: 'GEMLINE' },
  ['GEA']: { value: 'GEA', name: 'GENERAC' },
  ['FNP']: { value: 'FNP', name: 'GENERAL APPLIANCE' },
  ['GEC']: { value: 'GEC', name: 'GENERAL ELECTRIC' },
  ['GNF']: { value: 'GNF', name: 'GENERAL FREEZER' },
  ['GMO']: { value: 'GMO', name: 'GENERAL MOTORS' },
  ['GEE']: { value: 'GEE', name: 'GENERAL TIME CORPORA' },
  ['ELC']: { value: 'ELC', name: 'GENERIC ELECTRICAL' },
  ['GEG']: { value: 'GEG', name: 'GENEVA' },
  ['GNC']: { value: 'GNC', name: 'GENICOM' },
  ['GEN']: { value: 'GEN', name: 'GENIE' },
  ['GBT']: { value: 'GBT', name: 'GENIE BEAST' },
  ['GER']: { value: 'GER', name: 'GERALD' },
  ['GED']: { value: 'GED', name: 'GERRAD' },
  ['GIB']: { value: 'GIB', name: 'GIBSON' },
  ['GLF']: { value: 'GLF', name: 'GLADIATOR' },
  ['GLE']: { value: 'GLE', name: 'GLENWOOD' },
  ['GOV']: { value: 'GOV', name: 'GO VIDEO' },
  ['GOT']: { value: 'GOT', name: 'GOETTI' },
  ['GCF']: { value: 'GCF', name: 'GOLD CHEF' },
  ['GOL']: { value: 'GOL', name: 'GOLDSTAR' },
  ['GOD']: { value: 'GOD', name: 'GOODMAN' },
  ['GOO']: { value: 'GOO', name: 'GOODMAN SPEAKERS' },
  ['GRN']: { value: 'GRN', name: 'GRAN PRIX' },
  ['GRA']: { value: 'GRA', name: 'GRANDSTONE' },
  ['GRG']: { value: 'GRG', name: 'GRANDVISION' },
  ['GRV']: { value: 'GRV', name: 'GRAVIS (ADVANCED)' },
  ['GAA']: { value: 'GAA', name: 'GREAT AMERICAN AUDIO' },
  ['GRE']: { value: 'GRE', name: 'GREAT CONNECTIONS, I' },
  ['GRO']: { value: 'GRO', name: 'GROLEN' },
  ['GRC']: { value: 'GRC', name: 'GROLIER' },
  ['GWS']: { value: 'GWS', name: 'GTE WIRELESS   SEATT' },
  ['GWC']: { value: 'GWC', name: 'GTE WIRELESS CINCINN' },
  ['GUA']: { value: 'GUA', name: 'GUARDIAN' },
  ['GUI']: { value: 'GUI', name: 'GUIS AMERICA' },
  ['GUS']: { value: 'GUS', name: 'GUSDORF' },
  ['HCO']: { value: 'HCO', name: 'H COMPANY' },
  ['HHS']: { value: 'HHS', name: 'H.H.SCOTT' },
  ['HAF']: { value: 'HAF', name: 'HAFLER' },
  ['HAI']: { value: 'HAI', name: 'HAIER' },
  ['HBA']: { value: 'HBA', name: 'HAIER AMERICA' },
  ['HAL']: { value: 'HAL', name: 'HALLMARK' },
  ['HTM']: { value: 'HTM', name: 'HAMILTON' },
  ['HAM']: { value: 'HAM', name: 'HAMILTON BEACH' },
  ['HAB']: { value: 'HAB', name: 'HAMPTON BAY' },
  ['HBD']: { value: 'HBD', name: 'HAMPTON BAY D' },
  ['HBM']: { value: 'HBM', name: 'HAMPTON BAY M' },
  ['HAN']: { value: 'HAN', name: 'HANDSPRING' },
  ['HAD']: { value: 'HAD', name: 'HARDLINES SERVICES C' },
  ['HDW']: { value: 'HDW', name: 'HARDWICK' },
  ['HRD']: { value: 'HRD', name: 'HARLEY DAVISON' },
  ['HAR']: { value: 'HAR', name: 'HARMON KARDON' },
  ['HAV']: { value: 'HAV', name: 'HARVARD INTERIORS MF' },
  ['HAW']: { value: 'HAW', name: 'HAWS' },
  ['HEC']: { value: 'HEC', name: 'HEAD GAMES' },
  ['HEE']: { value: 'HEE', name: 'HEALTH MARK' },
  ['HEL']: { value: 'HEL', name: 'HEALTHOMETER' },
  ['HET']: { value: 'HET', name: 'HEARTLAND' },
  ['HTG']: { value: 'HTG', name: 'HEAT N GLOW' },
  ['HEI']: { value: 'HEI', name: 'HEIL' },
  ['HER']: { value: 'HER', name: 'HERITAGE' },
  ['HTH']: { value: 'HTH', name: 'HI TECH' },
  ['HGS']: { value: 'HGS', name: 'HIGHER GROUND SOFTWA' },
  ['HIG']: { value: 'HIG', name: 'HIGHLANDER' },
  ['HIR']: { value: 'HIR', name: 'HIRSH INDUSTRIESL IN' },
  ['HIT']: { value: 'HIT', name: 'HITACHI' },
  ['HOB']: { value: 'HOB', name: 'HOBART' },
  ['HOL']: { value: 'HOL', name: 'HOLIDAY' },
  ['HLD']: { value: 'HLD', name: 'HOLLAND' },
  ['HOA']: { value: 'HOA', name: 'HOMEDICS, INC' },
  ['HOM']: { value: 'HOM', name: 'HOMELITE' },
  ['HON']: { value: 'HON', name: 'HONDA' },
  ['HOY']: { value: 'HOY', name: 'HONEYWELL' },
  ['HOV']: { value: 'HOV', name: 'HOOVER' },
  ['HOS']: { value: 'HOS', name: 'HOSHIZAKI' },
  ['HMT']: { value: 'HMT', name: 'HOTMASTER' },
  ['HOT']: { value: 'HOT', name: 'HOTPOINT' },
  ['HUBB']: { value: 'HUBB', name: 'HUBBELL' },
  ['HUD']: { value: 'HUD', name: 'HUDSON' },
  ['HUE']: { value: 'HUE', name: 'HUEBSCH' },
  ['HUT']: { value: 'HUT', name: 'HUNTER' },
  ['HUC']: { value: 'HUC', name: 'HUSKY' },
  ['HQ']: { value: 'HQ', name: 'HUSQVARNA' },
  ['HUN']: { value: 'HUN', name: 'HYUNDAI' },
  ['IBM']: { value: 'IBM', name: 'IBM' },
  ['ICE']: { value: 'ICE', name: 'ICECAP' },
  ['ICG']: { value: 'ICG', name: 'ICON GRAPHIX. L.P.' },
  ['ICA']: { value: 'ICA', name: 'ICONCEPTS' },
  ['IPT']: { value: 'IPT', name: 'IMPACT RECORDS' },
  ['IMP']: { value: 'IMP', name: 'IMPERIAL' },
  ['IMF']: { value: 'IMF', name: 'INCA' },
  ['INU']: { value: 'INU', name: 'INCUS' },
  ['IAB']: { value: 'IAB', name: 'INDEPENDENCE' },
  ['INE']: { value: 'INE', name: 'INDEPENDENT' },
  ['IUA']: { value: 'IUA', name: 'INDEPENDENT UNITED D' },
  ['IND']: { value: 'IND', name: 'INDESIT' },
  ['IDM']: { value: 'IDM', name: 'INDUSTRIAL DESIGN MF' },
  ['ITS']: { value: 'ITS', name: 'INDUSTRIAL TRADE SUP' },
  ['INF']: { value: 'INF', name: 'INFINITY' },
  ['IGE']: { value: 'IGE', name: 'INFOGAMES ENTERTAINM' },
  ['ING']: { value: 'ING', name: 'INGRAM MICRO/MICRO D' },
  ['ISC']: { value: 'ISC', name: 'INSCAPE' },
  ['IST']: { value: 'IST', name: 'INSIGHT' },
  ['INS']: { value: 'INS', name: 'INSINKERATOR' },
  ['INC']: { value: 'INC', name: 'INSYNC' },
  ['IEO']: { value: 'IEO', name: 'INTER EGO' },
  ['INT']: { value: 'INT', name: 'INTERACT' },
  ['INK']: { value: 'INK', name: 'INTERCONTINENTAL ART' },
  ['ICC']: { value: 'ICC', name: 'INTERNATIONAL COMPON' },
  ['INR']: { value: 'INR', name: 'INTERTHERM' },
  ['INV']: { value: 'INV', name: 'INVITA' },
  ['ION']: { value: 'ION', name: 'ION' },
  ['IPC']: { value: 'IPC', name: 'IPC' },
  ['IRB']: { value: 'IRB', name: 'IROBOT' },
  ['ISA']: { value: 'ISA', name: 'IRS' },
  ['ISE']: { value: 'ISE', name: 'ISE' },
  ['ISL']: { value: 'ISL', name: 'ISLAND' },
  ['ISP']: { value: 'ISP', name: 'ISP REBATE PROGRAM' },
  ['JAH']: { value: 'JAH', name: 'JADE' },
  ['JAD']: { value: 'JAD', name: 'JADEVINE' },
  ['JMB']: { value: 'JMB', name: 'JAMCO' },
  ['JAN']: { value: 'JAN', name: 'JANITROL' },
  ['JBL']: { value: 'JBL', name: 'JBL' },
  ['JCP']: { value: 'JCP', name: 'JCPENNEY' },
  ['JPG']: { value: 'JPG', name: 'JCPENNEY GE' },
  ['JCI']: { value: 'JCI', name: 'JEITO CONCEPTS (MOVI' },
  ['JEN']: { value: 'JEN', name: 'JENN AIR' },
  ['JSN']: { value: 'JSN', name: 'JENSEN' },
  ['JET']: { value: 'JET', name: 'JET' },
  ['JUL']: { value: 'JUL', name: 'JULIETE' },
  ['JIT']: { value: 'JIT', name: 'JUSTIN TIME RECORDS' },
  ['JVC']: { value: 'JVC', name: 'JVC' },
  ['KJA']: { value: 'KJA', name: 'K. J. MILLER CORPORA' },
  ['KAM']: { value: 'KAM', name: 'KAMP' },
  ['KAN']: { value: 'KAN', name: 'KAN' },
  ['KAO']: { value: 'KAO', name: 'KAO' },
  ['KAP']: { value: 'KAP', name: 'KAPART' },
  ['KAA']: { value: 'KAA', name: 'KARCHER' },
  ['KAR']: { value: 'KAR', name: 'KARIBA' },
  ['KWA']: { value: 'KWA', name: 'KAWAI' },
  ['KAW']: { value: 'KAW', name: 'KAWASHO' },
  ['KEG']: { value: 'KEG', name: 'KELON USA' },
  ['KEY']: { value: 'KEY', name: 'KELSEY' },
  ['KEL']: { value: 'KEL', name: 'KELVINATOR' },
  ['KET']: { value: 'KET', name: 'KENITECH' },
  ['KEM']: { value: 'KEM', name: 'KENMORE' },
  ['KEO']: { value: 'KEO', name: 'KENOLA' },
  ['KEN']: { value: 'KEN', name: 'KENWOOD' },
  ['KEP']: { value: 'KEP', name: 'KENWOOD CAR' },
  ['KEC']: { value: 'KEC', name: 'KEYS CAR' },
  ['KTC']: { value: 'KTC', name: 'KEYTRONIC' },
  ['KIA']: { value: 'KIA', name: 'KICKER CAR' },
  ['KID']: { value: 'KID', name: 'KIDCOM' },
  ['KIE']: { value: 'KIE', name: 'KINDRED' },
  ['KKT']: { value: 'KKT', name: 'KING KITCHEN' },
  ['KGS']: { value: 'KGS', name: 'KINGS' },
  ['KIN']: { value: 'KIN', name: 'KINGSPOINT' },
  ['KGT']: { value: 'KGT', name: 'KINGSTON' },
  ['KIK']: { value: 'KIK', name: 'KIOSK SHIPPING' },
  ['KIR']: { value: 'KIR', name: 'KIRBY' },
  ['KIS']: { value: 'KIS', name: 'KISHO CORPORATION' },
  ['KAI']: { value: 'KAI', name: 'KIT AIRE' },
  ['KIF']: { value: 'KIF', name: 'KIT COMPANY' },
  ['KIT']: { value: 'KIT', name: 'KITCHEN AID' },
  ['KDR']: { value: 'KDR', name: 'KITCHEN DOCTOR' },
  ['KIP']: { value: 'KIP', name: 'KITCHENAID PORTABLE' },
  ['KLH']: { value: 'KLH', name: 'KLH' },
  ['KLM']: { value: 'KLM', name: 'KLM' },
  ['KLO']: { value: 'KLO', name: 'KLOSSON' },
  ['KMC']: { value: 'KMC', name: 'KMART' },
  ['KMB']: { value: 'KMB', name: 'KMB' },
  ['KOC']: { value: 'KOC', name: 'KOBE' },
  ['KOB']: { value: 'KOB', name: 'KOBLENZ' },
  ['KH']: { value: 'KH', name: 'KOHLER' },
  ['KOL']: { value: 'KOL', name: 'KOLDWAVE' },
  ['KOG']: { value: 'KOG', name: 'KOOLATRON' },
  ['KRA']: { value: 'KRA', name: 'KRAFT SYSTEMS INC' },
  ['KTI']: { value: 'KTI', name: 'KTI' },
  ['KTV']: { value: 'KTV', name: 'KTV' },
  ['KYS']: { value: 'KYS', name: 'KYSHO' },
  ['LGE']: { value: 'LGE', name: 'L G ELECTRONICS' },
  ['LAJ']: { value: 'LAJ', name: 'LA CORNUE' },
  ['LSC']: { value: 'LSC', name: 'LANZAR SOUND CORP' },
  ['LAH']: { value: 'LAH', name: 'LASKO METAL PRODUCTS' },
  ['LAW']: { value: 'LAW', name: 'LAWNBOY' },
  ['LAZ']: { value: 'LAZ', name: 'LAZY MAN' },
  ['LEA']: { value: 'LEA', name: 'LEADINGEDGE' },
  ['LEC']: { value: 'LEC', name: 'LECHMERE' },
  ['LEI']: { value: 'LEI', name: 'LEIBERT' },
  ['LEM']: { value: 'LEM', name: 'LENMAR' },
  ['LEN']: { value: 'LEN', name: 'LENNOX' },
  ['LGA']: { value: 'LGA', name: 'LG' },
  ['LII']: { value: 'LII', name: 'LIEBHERR' },
  ['LIF']: { value: 'LIF', name: 'LIFE STRIDER' },
  ['LIE']: { value: 'LIE', name: 'LIGHTNING' },
  ['LIT']: { value: 'LIT', name: 'LITTON' },
  ['LLO']: { value: 'LLO', name: 'LLOYDS' },
  ['LCH']: { value: 'LCH', name: 'LOCHINVA' },
  ['LOC']: { value: 'LOC', name: 'LOCHINVAR' },
  ['LOG']: { value: 'LOG', name: 'LOGIC' },
  ['LOT']: { value: 'LOT', name: 'LOTUS' },
  ['LUC']: { value: 'LUC', name: 'LUCENT TECHNOLOGY' },
  ['LUM']: { value: 'LUM', name: 'LUMISCOPE' },
  ['LXA']: { value: 'LXA', name: 'LUXAIRE' },
  ['LUX']: { value: 'LUX', name: 'LUXMAN' },
  ['LYN']: { value: 'LYN', name: 'LYNX' },
  ['MABE']: { value: 'MABE', name: 'MABE' },
  ['MAC']: { value: 'MAC', name: 'MACDONALD' },
  ['MTY']: { value: 'MTY', name: 'MAG TECHNOLOGY' },
  ['MCH']: { value: 'MCH', name: 'MAGIC CHEF' },
  ['MAD']: { value: 'MAD', name: 'MAGIC CLAMP' },
  ['MAG']: { value: 'MAG', name: 'MAGNAVOX' },
  ['MGN']: { value: 'MGN', name: 'MAGNIN' },
  ['MAI']: { value: 'MAI', name: 'MAITLAND SMITH LTD' },
  ['MAJ']: { value: 'MAJ', name: 'MAJESTIC' },
  ['MAE']: { value: 'MAE', name: 'MAKIE' },
  ['MAL']: { value: 'MAL', name: 'MALBER' },
  ['MAM']: { value: 'MAM', name: 'MAMIYA' },
  ['MAN']: { value: 'MAN', name: 'MAN ITOWOC' },
  ['MAH']: { value: 'MAH', name: 'MANITOWOC' },
  ['MAR']: { value: 'MAR', name: 'MARANTZ' },
  ['MKR']: { value: 'MKR', name: 'MARK ROYAL' },
  ['MAQ']: { value: 'MAQ', name: 'MARQUETTE' },
  ['MSH']: { value: 'MSH', name: 'MARSHALL' },
  ['MTA']: { value: 'MTA', name: 'MARTA' },
  ['MTI']: { value: 'MTI', name: 'MARTIN IND' },
  ['MVA']: { value: 'MVA', name: 'MARVAIR' },
  ['MEL']: { value: 'MEL', name: 'MARVEL' },
  ['MSC']: { value: 'MSC', name: 'MASTER CARE' },
  ['MAU']: { value: 'MAU', name: 'MASTER MANUFACTURING' },
  ['MAS']: { value: 'MAS', name: 'MASTERCOOL' },
  ['MAT']: { value: 'MAT', name: 'MATSUCHITA' },
  ['MAV']: { value: 'MAV', name: 'MAVA' },
  ['MXX']: { value: 'MXX', name: 'MAX SYSTEM HEADER' },
  ['MAX']: { value: 'MAX', name: 'MAXELL' },
  ['MMS']: { value: 'MMS', name: 'MAXIMUS' },
  ['MXS']: { value: 'MXS', name: 'MAXISWITCH' },
  ['MCR']: { value: 'MCR', name: 'MAYCORE' },
  ['MAY']: { value: 'MAY', name: 'MAYTAG' },
  ['MYG']: { value: 'MYG', name: 'MAYTAG RAC' },
  ['MBA']: { value: 'MBA', name: 'MBQUART CAR' },
  ['MC']: { value: 'MC', name: 'MCCULLOCH' },
  ['MCG']: { value: 'MCG', name: 'MCGRAW' },
  ['MCQ']: { value: 'MCQ', name: 'MCQUAY' },
  ['MCS']: { value: 'MCS', name: 'MCS' },
  ['MEC']: { value: 'MEC', name: 'MEC' },
  ['MGH']: { value: 'MGH', name: 'MEGAHERTZ' },
  ['MEM']: { value: 'MEM', name: 'MEMOREX' },
  ['MEE']: { value: 'MEE', name: 'MERISEL' },
  ['MER']: { value: 'MER', name: 'MERRITT' },
  ['MES']: { value: 'MES', name: 'MESA' },
  ['MGA']: { value: 'MGA', name: 'MGA' },
  ['MGC']: { value: 'MGC', name: 'MGC' },
  ['MGT']: { value: 'MGT', name: 'MGN TECH' },
  ['MEI']: { value: 'MEI', name: 'MICRO ELECTRONICS' },
  ['MIC']: { value: 'MIC', name: 'MICROFRIDGE' },
  ['MCN']: { value: 'MCN', name: 'MICRONET' },
  ['MIR']: { value: 'MIR', name: 'MICROSOFT' },
  ['MCK']: { value: 'MCK', name: 'MICROTECH LAB' },
  ['MDT']: { value: 'MDT', name: 'MID TOWER' },
  ['MIL']: { value: 'MIL', name: 'MIDDLESEX' },
  ['MIW']: { value: 'MIW', name: 'MIDEA USA, INC' },
  ['MID']: { value: 'MID', name: 'MIDLAND' },
  ['MIE']: { value: 'MIE', name: 'MIELE' },
  ['MII']: { value: 'MII', name: 'MIIDA' },
  ['MLM']: { value: 'MLM', name: 'MILLENNIUM' },
  ['MLR']: { value: 'MLR', name: 'MILLER' },
  ['MIN']: { value: 'MIN', name: 'MINOLTA' },
  ['MIK']: { value: 'MIK', name: 'MIRAGE' },
  ['MIF']: { value: 'MIF', name: 'MISCELLANEOUS' },
  ['MIT']: { value: 'MIT', name: 'MITSUBISHI' },
  ['MCF']: { value: 'MCF', name: 'MODERN CHIEF' },
  ['MOD']: { value: 'MOD', name: 'MODERN MAID' },
  ['MOK']: { value: 'MOK', name: 'MODERNAIR' },
  ['MOE']: { value: 'MOE', name: 'MODERNHOMEPR' },
  ['MTH']: { value: 'MTH', name: 'MONMOUTH' },
  ['MON']: { value: 'MON', name: 'MONOGRAM' },
  ['MOM']: { value: 'MOM', name: 'MONORAIL' },
  ['MOA']: { value: 'MOA', name: 'MONSTER CABLE' },
  ['MWA']: { value: 'MWA', name: 'MONTGOMERY WARD' },
  ['MFO']: { value: 'MFO', name: 'MOR FLO' },
  ['MOR']: { value: 'MOR', name: 'MORRIS' },
  ['MOT']: { value: 'MOT', name: 'MOTOROLA' },
  ['MTN']: { value: 'MTN', name: 'MOUNTAIN' },
  ['MSY']: { value: 'MSY', name: 'MOUSE SYSTEMS' },
  ['MTC']: { value: 'MTC', name: 'MTC' },
  ['MUL']: { value: 'MUL', name: 'MULTI BRAND' },
  ['MSN']: { value: 'MSN', name: 'MULTISPIN' },
  ['MLT']: { value: 'MLT', name: 'MULTITECH' },
  ['MUT']: { value: 'MUT', name: 'MURATEC' },
  ['MRR']: { value: 'MRR', name: 'MURRAY' },
  ['MRY']: { value: 'MRY', name: 'MURRAY OHIO' },
  ['MYS']: { value: 'MYS', name: 'MYSON' },
  ['NAD']: { value: 'NAD', name: 'NAD' },
  ['NAK']: { value: 'NAK', name: 'NAKAMICHI' },
  ['NAP']: { value: 'NAP', name: 'NAP' },
  ['NAT']: { value: 'NAT', name: 'NATIONAL' },
  ['NTL']: { value: 'NTL', name: 'NATIONALINE' },
  ['NAA']: { value: 'NAA', name: 'NATIONWIDE FURNITURE' },
  ['NTM']: { value: 'NTM', name: 'NATM' },
  ['NAU']: { value: 'NAU', name: 'NAUTILUS' },
  ['NAY']: { value: 'NAY', name: 'NAYBORHOODZ' },
  ['NDA']: { value: 'NDA', name: 'NDA WHOLESALE' },
  ['NEF']: { value: 'NEF', name: 'NEW ENGLAND FURNITUR' },
  ['NCM']: { value: 'NCM', name: 'NEWCOM' },
  ['NML']: { value: 'NML', name: 'NEWMARK LEWIS' },
  ['NEP']: { value: 'NEP', name: 'NEWPOINT CORP' },
  ['NEK']: { value: 'NEK', name: 'NEXGRILL' },
  ['NEG']: { value: 'NEG', name: 'NEXTEL' },
  ['NKO']: { value: 'NKO', name: 'NIKKO' },
  ['NIK']: { value: 'NIK', name: 'NIKON' },
  ['NTN']: { value: 'NTN', name: 'NINTENDO' },
  ['NEC']: { value: 'NEC', name: 'NIPPON ELECTRIC CORP' },
  ['NOK']: { value: 'NOK', name: 'NOKIA' },
  ['NMA']: { value: 'NMA', name: 'NOMA' },
  ['NCD']: { value: 'NCD', name: 'NORCOLD' },
  ['NCO']: { value: 'NCO', name: 'NORELCO' },
  ['NOR']: { value: 'NOR', name: 'NORGE' },
  ['NAM']: { value: 'NAM', name: 'NORTH AMERICAN' },
  ['NRW']: { value: 'NRW', name: 'NORTH WIND' },
  ['NOT']: { value: 'NOT', name: 'NORTHLAND' },
  ['NTS']: { value: 'NTS', name: 'NORTHSTAR' },
  ['NOV']: { value: 'NOV', name: 'NOVA INDUSTRIES' },
  ['NRG']: { value: 'NRG', name: 'NRG' },
  ['NUT']: { value: 'NUT', name: 'NUTONE' },
  ['DURO']: { value: 'DURO', name: 'NXR' },
  ['OAS']: { value: 'OAS', name: 'OASIS' },
  ['OCE']: { value: 'OCE', name: 'OCEAN' },
  ['OKE']: { value: 'OKE', name: 'OKEEFE' },
  ['OKR']: { value: 'OKR', name: 'OKRON' },
  ['OLI']: { value: 'OLI', name: 'OLIVETTI' },
  ['OLM']: { value: 'OLM', name: 'OLM' },
  ['OLP']: { value: 'OLP', name: 'OLYMPIA' },
  ['OMN']: { value: 'OMN', name: 'OMNI' },
  ['OMR']: { value: 'OMR', name: 'OMRON' },
  ['ONK']: { value: 'ONK', name: 'ONKYO' },
  ['ONW']: { value: 'ONW', name: 'ONWA' },
  ['OPA']: { value: 'OPA', name: 'OPAL' },
  ['OPT']: { value: 'OPT', name: 'OPTONICA' },
  ['ORB']: { value: 'ORB', name: 'ORBITAL' },
  ['OBN']: { value: 'OBN', name: 'ORBON' },
  ['ORC']: { value: 'ORC', name: 'ORCHARD' },
  ['ORK']: { value: 'ORK', name: 'ORECK' },
  ['ORE']: { value: 'ORE', name: 'OREON' },
  ['QWS']: { value: 'QWS', name: 'ORIENT ELECTRONICS' },
  ['ORI']: { value: 'ORI', name: 'ORION' },
  ['ORT']: { value: 'ORT', name: 'ORTOFON' },
  ['OSA']: { value: 'OSA', name: 'OSAWA' },
  ['OST']: { value: 'OST', name: 'OSTER' },
  ['OTB']: { value: 'OTB', name: 'OTHER MANUFACTURES' },
  ['PEL']: { value: 'PEL', name: 'PACIFIC ELECTRIC' },
  ['PCK']: { value: 'PCK', name: 'PACKARD BELL' },
  ['PAO']: { value: 'PAO', name: 'PACOM' },
  ['PAG']: { value: 'PAG', name: 'PAGEMART WIRELESS' },
  ['PAL']: { value: 'PAL', name: 'PALM' },
  ['PAN']: { value: 'PAN', name: 'PANASONIC' },
  ['PAK']: { value: 'PAK', name: 'PANDO' },
  ['PAA']: { value: 'PAA', name: 'PARADINE' },
  ['PAB']: { value: 'PAB', name: 'PARANA RIBBONS' },
  ['PAC']: { value: 'PAC', name: 'PASSAIC' },
  ['PAY']: { value: 'PAY', name: 'PAYNE' },
  ['PEE']: { value: 'PEE', name: 'PEERLESS' },
  ['PEN']: { value: 'PEN', name: 'PENN CREST' },
  ['PNX']: { value: 'PNX', name: 'PENTAX' },
  ['PFC']: { value: 'PFC', name: 'PERFECTION' },
  ['PER']: { value: 'PER', name: 'PERFORMA' },
  ['PEK']: { value: 'PEK', name: 'PERLICK' },
  ['PHT']: { value: 'PHT', name: 'PHANTOM' },
  ['PHD']: { value: 'PHD', name: 'PHD., INC' },
  ['PHI']: { value: 'PHI', name: 'PHILCO' },
  ['PHP']: { value: 'PHP', name: 'PHILIPS' },
  ['PHO']: { value: 'PHO', name: 'PHONE MATE' },
  ['PLT']: { value: 'PLT', name: 'PILOT' },
  ['PIO']: { value: 'PIO', name: 'PIONEER' },
  ['PII']: { value: 'PII', name: 'PIXIE' },
  ['PLF']: { value: 'PLF', name: 'POLYFLAME' },
  ['PVO']: { value: 'PVO', name: 'PORTA VIDEO' },
  ['POT']: { value: 'POT', name: 'PORTER CABLE' },
  ['POR']: { value: 'POR', name: 'PORTLAND' },
  ['PRB']: { value: 'PRB', name: 'PRB' },
  ['PRC']: { value: 'PRC', name: 'PRC' },
  ['PIT']: { value: 'PIT', name: 'PRECISION INST' },
  ['PRE']: { value: 'PRE', name: 'PREMIER' },
  ['PRQ']: { value: 'PRQ', name: 'PRESTIGE' },
  ['PCL']: { value: 'PCL', name: 'PRICE CLUB' },
  ['PCS']: { value: 'PCS', name: 'PRINCESS' },
  ['PRI']: { value: 'PRI', name: 'PRISM' },
  ['PRF']: { value: 'PRF', name: 'PRO FORM' },
  ['PRS']: { value: 'PRS', name: 'PRO SCAN' },
  ['PRM']: { value: 'PRM', name: 'PROCOM' },
  ['PDY']: { value: 'PDY', name: 'PRODIGY SERVICES COM' },
  ['PTG']: { value: 'PTG', name: 'PROFESSIONAL TECHNOL' },
  ['PFL']: { value: 'PFL', name: 'PROFILE' },
  ['PFP']: { value: 'PFP', name: 'PROFILE PERFORMANCE' },
  ['PRZ']: { value: 'PRZ', name: 'PROGRESSIVE' },
  ['PRL']: { value: 'PRL', name: 'PROLINEA' },
  ['PTH']: { value: 'PTH', name: 'PROTECH' },
  ['PRW']: { value: 'PRW', name: 'PROVIEW' },
  ['PST']: { value: 'PST', name: 'PST' },
  ['PUM']: { value: 'PUM', name: 'PURCELL MURRAY' },
  ['QUA']: { value: 'QUA', name: 'QUASAR' },
  ['QUI']: { value: 'QUI', name: 'QUICKSHOT' },
  ['QUD']: { value: 'QUD', name: 'QUIET LINE' },
  ['RAD']: { value: 'RAD', name: 'RADIUS' },
  ['RAI']: { value: 'RAI', name: 'RAINSOFT' },
  ['RAL']: { value: 'RAL', name: 'RALLY' },
  ['RAMD']: { value: 'RAMD', name: 'RAMDSON' },
  ['RAM']: { value: 'RAM', name: 'RAMSA' },
  ['RDX']: { value: 'RDX', name: 'RANDIX' },
  ['RGE']: { value: 'RGE', name: 'RANGEAIRE' },
  ['RAN']: { value: 'RAN', name: 'RANGEAIRE CORP' },
  ['RGM']: { value: 'RGM', name: 'RANGEMASTER' },
  ['RAG']: { value: 'RAG', name: 'RANGER' },
  ['RCA']: { value: 'RCA', name: 'RCA' },
  ['RCW']: { value: 'RCW', name: 'RCA WHITE' },
  ['REA']: { value: 'REA', name: 'REALISTIC' },
  ['REW']: { value: 'REW', name: 'REGAL WARE INC' },
  ['REG']: { value: 'REG', name: 'REGENCY' },
  ['RGN']: { value: 'RGN', name: 'REGINA' },
  ['REL']: { value: 'REL', name: 'RELIANCE' },
  ['REN']: { value: 'REN', name: 'RENEWABLE RESOURCE' },
  ['RER']: { value: 'RER', name: 'REPRO CRAFTERS, INC' },
  ['RES']: { value: 'RES', name: 'RESEARCH' },
  ['RVC']: { value: 'RVC', name: 'REVCO' },
  ['REV']: { value: 'REV', name: 'REVOX' },
  ['RHE']: { value: 'RHE', name: 'RHEEM' },
  ['RCC']: { value: 'RCC', name: 'RICCAR' },
  ['RID']: { value: 'RID', name: 'RIDGID' },
  ['ROA']: { value: 'ROA', name: 'ROADMASTER' },
  ['BOX']: { value: 'BOX', name: 'ROADSHOP INVENTORY' },
  ['ROB']: { value: 'ROB', name: 'ROBBINS' },
  ['RBS']: { value: 'RBS', name: 'ROBERTSHAW' },
  ['ROG']: { value: 'ROG', name: 'ROGERS' },
  ['ROL']: { value: 'ROL', name: 'ROLAND' },
  ['RON']: { value: 'RON', name: 'RONSON' },
  ['ROP']: { value: 'ROP', name: 'ROPER' },
  ['ROS']: { value: 'ROS', name: 'ROSHCO' },
  ['ROT']: { value: 'ROT', name: 'ROTEL' },
  ['RYL']: { value: 'RYL', name: 'ROYAL' },
  ['RYA']: { value: 'RYA', name: 'ROYAL APPLIANCE' },
  ['ROY']: { value: 'ROY', name: 'ROYAL BUSINESS MACHI' },
  ['ROD']: { value: 'ROD', name: 'ROYAL ROSE' },
  ['RTA']: { value: 'RTA', name: 'RTA CARTS' },
  ['RUS']: { value: 'RUS', name: 'RUSSEL' },
  ['RUD']: { value: 'RUD', name: 'RUUD' },
  ['RYB']: { value: 'RYB', name: 'RYOBI' },
  ['SBE']: { value: 'SBE', name: 'SABER' },
  ['SAG']: { value: 'SAG', name: 'SAGER' },
  ['SAL']: { value: 'SAL', name: 'SALTER' },
  ['SAM']: { value: 'SAM', name: 'SAMPO' },
  ['SAS']: { value: 'SAS', name: 'SAMSUNG' },
  ['SMD']: { value: 'SMD', name: 'SAMSUNG ELECTRONICS' },
  ['SMG']: { value: 'SMG', name: 'SAMSUNGX' },
  ['SYO']: { value: 'SYO', name: 'SANKYO' },
  ['SUI']: { value: 'SUI', name: 'SANSUI' },
  ['SNT']: { value: 'SNT', name: 'SANTAIRE' },
  ['SAA']: { value: 'SAA', name: 'SANTECA' },
  ['SAN']: { value: 'SAN', name: 'SANYO' },
  ['SAI']: { value: 'SAI', name: 'SAS BAZOOKA' },
  ['SLT']: { value: 'SLT', name: 'SATTELITE' },
  ['SAT']: { value: 'SAT', name: 'SATURN' },
  ['SBR']: { value: 'SBR', name: 'SCHREIBER' },
  ['SFY']: { value: 'SFY', name: 'SCIENTIFIC TOYS' },
  ['STM']: { value: 'STM', name: 'SCOTSMAN' },
  ['SCO']: { value: 'SCO', name: 'SCOTT' },
  ['SCT']: { value: 'SCT', name: 'SCOTTSMAN' },
  ['SEA']: { value: 'SEA', name: 'SEARS' },
  ['SEG']: { value: 'SEG', name: 'SEGA' },
  ['SEB']: { value: 'SEB', name: 'SENSIBLE STORAGE' },
  ['SEV']: { value: 'SEV', name: 'SEVILL' },
  ['SFS']: { value: 'SFS', name: 'SFS' },
  ['CTF']: { value: 'CTF', name: 'SGD CORP' },
  ['SHA']: { value: 'SHA', name: 'SHARP' },
  ['SEC']: { value: 'SEC', name: 'SHARP ELECTRONIC CO' },
  ['SHV']: { value: 'SHV', name: 'SHARPVISION' },
  ['SHE']: { value: 'SHE', name: 'SHERWOOD' },
  ['SHI']: { value: 'SHI', name: 'SHINTON' },
  ['SIE']: { value: 'SIE', name: 'SIEMENS' },
  ['SIG']: { value: 'SIG', name: 'SIGNATURE' },
  ['SIT']: { value: 'SIT', name: 'SIGNATURE LIGHTING ' },
  ['SIL']: { value: 'SIL', name: 'SILVER MARSHALL' },
  ['SMP']: { value: 'SMP', name: 'SIMPLE' },
  ['SNK']: { value: 'SNK', name: 'SINKMASTER' },
  ['SIN']: { value: 'SIN', name: 'SINTON' },
  ['SLA']: { value: 'SLA', name: 'SLATTERY' },
  ['SMF']: { value: 'SMF', name: 'SMART PLANET' },
  ['SOV']: { value: 'SOV', name: 'SOFTLINE' },
  ['SOL']: { value: 'SOL', name: 'SOLARIS' },
  ['SOW']: { value: 'SOW', name: 'SOLEUS' },
  ['SOD']: { value: 'SOD', name: 'SOLID' },
  ['SON']: { value: 'SON', name: 'SONY' },
  ['SOG']: { value: 'SOG', name: 'SONY PRO' },
  ['SOU']: { value: 'SOU', name: 'SOUND DESIGN' },
  ['SOR']: { value: 'SOR', name: 'SOUNDSTER' },
  ['SBD']: { value: 'SBD', name: 'SOUTHBEND' },
  ['SPE']: { value: 'SPE', name: 'SPECRUM' },
  ['SSE']: { value: 'SSE', name: 'SPECTRA MERCHANDISIN' },
  ['SPD']: { value: 'SPD', name: 'SPECTRA PHONE' },
  ['SPC']: { value: 'SPC', name: 'SPECTRICON' },
  ['SAC']: { value: 'SAC', name: 'SPECTRO ACOUSTIC' },
  ['SPQ']: { value: 'SPQ', name: 'SPEED QUEEN' },
  ['SPL']: { value: 'SPL', name: 'SPLENDIDE' },
  ['STF']: { value: 'STF', name: 'SPORTSTRONICS' },
  ['SQU']: { value: 'SQU', name: 'SQUARE SOFT, INC' },
  ['STL']: { value: 'STL', name: 'STANLEY' },
  ['STA']: { value: 'STA', name: 'STAR' },
  ['STK']: { value: 'STK', name: 'STAR QUALITY' },
  ['STT']: { value: 'STT', name: 'STATE' },
  ['STS']: { value: 'STS', name: 'STATES' },
  ['STC']: { value: 'STC', name: 'STEMPEL MANUFACTURIN' },
  ['STE']: { value: 'STE', name: 'STERLING' },
  ['STG']: { value: 'STG', name: 'STERLING MULTIMEDIA' },
  ['STO']: { value: 'STO', name: 'STORM SOFTWARE' },
  ['SVA']: { value: 'SVA', name: 'STOVES OF AMERICA' },
  ['SUB']: { value: 'SUB', name: 'SUB ZERO' },
  ['SRN']: { value: 'SRN', name: 'SUBURBAN' },
  ['SMC']: { value: 'SMC', name: 'SUMMAGRAPHICS' },
  ['SUM']: { value: 'SUM', name: 'SUMMIT' },
  ['SNU']: { value: 'SNU', name: 'SUN' },
  ['SBM']: { value: 'SBM', name: 'SUNBEAM' },
  ['SUN']: { value: 'SUN', name: 'SUNRAY' },
  ['SUP']: { value: 'SUP', name: 'SUPERSCOPE' },
  ['SUA']: { value: 'SUA', name: 'SUPRA' },
  ['SPR']: { value: 'SPR', name: 'SUPREMACY' },
  ['SUR']: { value: 'SUR', name: 'SURE' },
  ['SUG']: { value: 'SUG', name: 'SUSTEEN' },
  ['SYB']: { value: 'SYB', name: 'SYB' },
  ['SYL']: { value: 'SYL', name: 'SYLVANIA' },
  ['SYM']: { value: 'SYM', name: 'SYMPHONIC' },
  ['SYN']: { value: 'SYN', name: 'SYNERGISTICS' },
  ['SYS']: { value: 'SYS', name: 'SYSTEMAX' },
  ['TAI']: { value: 'TAI', name: 'TAIHAN' },
  ['TAL']: { value: 'TAL', name: 'TALC' },
  ['TAR']: { value: 'TAR', name: 'TAMRON' },
  ['TAN']: { value: 'TAN', name: 'TANDBERG' },
  ['TDY']: { value: 'TDY', name: 'TANDY' },
  ['TAP']: { value: 'TAP', name: 'TAPPAN' },
  ['TAG']: { value: 'TAG', name: 'TARGUS' },
  ['TAS']: { value: 'TAS', name: 'TASCAM' },
  ['TAT']: { value: 'TAT', name: 'TATUNG' },
  ['TDRSYHS']: { value: 'TDRSYHS', name: 'TDRSYHS' },
  ['TEA']: { value: 'TEA', name: 'TEAC' },
  ['TBA']: { value: 'TBA', name: 'TEBA' },
  ['TCE']: { value: 'TCE', name: 'TEC' },
  ['SRS']: { value: 'SRS', name: 'TECH DATA DISTRIBUTO' },
  ['TCF']: { value: 'TCF', name: 'TECHCRAFT' },
  ['TEC']: { value: 'TEC', name: 'TECHNICS' },
  ['TEH']: { value: 'TEH', name: 'TECHNOWIND' },
  ['TKW']: { value: 'TKW', name: 'TECKWOOD' },
  ['TEO']: { value: 'TEO', name: 'TECO' },
  ['TEK']: { value: 'TEK', name: 'TEKNICA' },
  ['TNK']: { value: 'TNK', name: 'TEKNIKA' },
  ['TEL']: { value: 'TEL', name: 'TELERENT' },
  ['TVT']: { value: 'TVT', name: 'TELEVIDEO TERMINALS' },
  ['TEM']: { value: 'TEM', name: 'TEMPMASTERS' },
  ['TMP']: { value: 'TMP', name: 'TEMPSTAR' },
  ['TER']: { value: 'TER', name: 'TERA' },
  ['HUB']: { value: 'HUB', name: 'THE HUNTINGTON COMPA' },
  ['THM']: { value: 'THM', name: 'THERM TECHNOLOGY' },
  ['THS']: { value: 'THS', name: 'THERMA STOR' },
  ['THE']: { value: 'THE', name: 'THERMADOR' },
  ['THR']: { value: 'THR', name: 'THERMO' },
  ['THD']: { value: 'THD', name: 'THERMO DYNAMICS' },
  ['TPL']: { value: 'TPL', name: 'THERMOPLUS' },
  ['THI']: { value: 'THI', name: 'THIRD GENERATION MAR' },
  ['TMS']: { value: 'TMS', name: 'THOMAS' },
  ['THP']: { value: 'THP', name: 'THOMPSON' },
  ['TOM']: { value: 'TOM', name: 'THOMSON' },
  ['THB']: { value: 'THB', name: 'THOR' },
  ['THO']: { value: 'THO', name: 'THORENS' },
  ['TFF']: { value: 'TFF', name: 'TIFFIN' },
  ['TIL']: { value: 'TIL', name: 'TILIA INC' },
  ['TOA']: { value: 'TOA', name: 'TOASTMASTER' },
  ['TOP']: { value: 'TOP', name: 'TOPP' },
  ['TSN']: { value: 'TSN', name: 'TORISAN' },
  ['TOR']: { value: 'TOR', name: 'TORO' },
  ['TOS']: { value: 'TOS', name: 'TOSHIBA' },
  ['TOT']: { value: 'TOT', name: 'TOTALHOMEENT' },
  ['TOY']: { value: 'TOY', name: 'TOYMENKA' },
  ['TRM']: { value: 'TRM', name: 'TRADEMARK' },
  ['TRE']: { value: 'TRE', name: 'TRADEPOWER LTD.' },
  ['TNE']: { value: 'TNE', name: 'TRANE' },
  ['TRL']: { value: 'TRL', name: 'TRANSTHERM' },
  ['TRA']: { value: 'TRA', name: 'TRAULSEN' },
  ['TRD']: { value: 'TRD', name: 'TRIDENT' },
  ['TRI']: { value: 'TRI', name: 'TRINITRON' },
  ['TRP']: { value: 'TRP', name: 'TRIPP LITE' },
  ['TRUM']: { value: 'TRUM', name: 'TRUE MANUFACTURING' },
  ['TRT']: { value: 'TRT', name: 'TRUE TEMP' },
  ['TUN']: { value: 'TUN', name: 'TUNTURI, INC' },
  ['TUR']: { value: 'TUR', name: 'TURFMASTER' },
  ['PIN']: { value: 'PIN', name: 'TV' },
  ['TWH']: { value: 'TWH', name: 'TWINHEAD' },
  ['ULI']: { value: 'ULI', name: 'U LINE' },
  ['UCM']: { value: 'UCM', name: 'U.C.M.' },
  ['USE']: { value: 'USE', name: 'U.S. ELECTRONICS INC' },
  ['ULF']: { value: 'ULF', name: 'ULTRA FORCE' },
  ['ULT']: { value: 'ULT', name: 'ULTRX' },
  ['UNI']: { value: 'UNI', name: 'UNI SYNC' },
  ['UNO']: { value: 'UNO', name: 'UNICO' },
  ['UNN']: { value: 'UNN', name: 'UNION' },
  ['USC']: { value: 'USC', name: 'UNISONIC' },
  ['UNS']: { value: 'UNS', name: 'UNISOURCE' },
  ['UTD']: { value: 'UTD', name: 'UNITED' },
  ['UNC']: { value: 'UNC', name: 'UNITED COMMERCIAL' },
  ['UTS']: { value: 'UTS', name: 'UNITED SERVICE' },
  ['UNJ']: { value: 'UNJ', name: 'UNITED STATIONERS' },
  ['UNT']: { value: 'UNT', name: 'UNITRONICS' },
  ['UVL']: { value: 'UVL', name: 'UNIVERSAL' },
  ['UNV']: { value: 'UNV', name: 'UNIVERSAL EMPOWERING' },
  ['UNW']: { value: 'UNW', name: 'UNWIRED' },
  ['UPK']: { value: 'UPK', name: 'UPKEEPER' },
  ['USA']: { value: 'USA', name: 'USA' },
  ['VAC']: { value: 'VAC', name: 'VAC' },
  ['VAN']: { value: 'VAN', name: 'VANGUARD' },
  ['VAR']: { value: 'VAR', name: 'VARIAN' },
  ['VAX']: { value: 'VAX', name: 'VAX' },
  ['VEC']: { value: 'VEC', name: 'VECTOR RESEARCH' },
  ['VPC']: { value: 'VPC', name: 'VENDEX PACIFIC' },
  ['VEM']: { value: 'VEM', name: 'VENMAR' },
  ['VEA']: { value: 'VEA', name: 'VENMARHOOD' },
  ['VET']: { value: 'VET', name: 'VENT A AIRE' },
  ['VEN']: { value: 'VEN', name: 'VENTAHOOD' },
  ['VER']: { value: 'VER', name: 'VERIT' },
  ['VEB']: { value: 'VEB', name: 'VERONA' },
  ['VES']: { value: 'VES', name: 'VESTA' },
  ['VIY']: { value: 'VIY', name: 'VICTORY LAND GROUP,' },
  ['VID']: { value: 'VID', name: 'VIDEO AID' },
  ['VDC']: { value: 'VDC', name: 'VIDEO CONCEPTS' },
  ['VOP']: { value: 'VOP', name: 'VIDEO PAL' },
  ['VIC']: { value: 'VIC', name: 'VIDEO CONCEPT' },
  ['VIK']: { value: 'VIK', name: 'VIKING' },
  ['VIN']: { value: 'VIN', name: 'VINTAGE' },
  ['VCN']: { value: 'VCN', name: 'VISCAN' },
  ['VSN']: { value: 'VSN', name: 'VISIONEER' },
  ['VIS']: { value: 'VIS', name: 'VISTA' },
  ['VLN']: { value: 'VLN', name: 'VLN' },
  ['VOR']: { value: 'VOR', name: 'VORNADO AIR CIRCULAT' },
  ['VTE']: { value: 'VTE', name: 'VTECH' },
  ['VUL']: { value: 'VUL', name: 'VULCAN' },
  ['WRL']: { value: 'WRL', name: 'W R LIGHT CO INC (CO)' },
  ['WHE']: { value: 'WHE', name: 'W.H. ELEVATORS' },
  ['WAC']: { value: 'WAC', name: 'WACOM' },
  ['WAL']: { value: 'WAL', name: 'WALD' },
  ['WMH']: { value: 'WMH', name: 'WARM MORNING SPACE H' },
  ['WTH']: { value: 'WTH', name: 'WARRANTECH' },
  ['WAR']: { value: 'WAR', name: 'WARRENTECH' },
  ['WAS']: { value: 'WAS', name: 'WASTE KING' },
  ['WAT']: { value: 'WAT', name: 'WATERWARE' },
  ['WTT']: { value: 'WTT', name: 'WATTS' },
  ['WKG']: { value: 'WKG', name: 'WEATHERKING' },
  ['WEB']: { value: 'WEB', name: 'WEBER' },
  ['WEG']: { value: 'WEG', name: 'WEBER GRILL' },
  ['WDN']: { value: 'WDN', name: 'WELDON' },
  ['WEL']: { value: 'WEL', name: 'WELLBUILT' },
  ['WET']: { value: 'WET', name: 'WELTON' },
  ['WEO']: { value: 'WEO', name: 'WESLO' },
  ['WEC']: { value: 'WEC', name: 'WESTCO' },
  ['WEA']: { value: 'WEA', name: 'WESTERN AUTO' },
  ['WDC']: { value: 'WDC', name: 'WESTERN DIGITAL' },
  ['WES']: { value: 'WES', name: 'WESTINGHOUSE' },
  ['WPT']: { value: 'WPT', name: 'WESTPORT' },
  ['WEW']: { value: 'WEW', name: 'WESTWOOD LIGHTING' },
  ['WHA']: { value: 'WHA', name: 'WHARFEDALE AMERICA' },
  ['WTN']: { value: 'WTN', name: 'WHELTON' },
  ['WHI']: { value: 'WHI', name: 'WHIRLAWAY' },
  ['WHP']: { value: 'WHP', name: 'WHIRLPOOL' },
  ['WHS']: { value: 'WHS', name: 'WHISTLER' },
  ['WHT']: { value: 'WHT', name: 'WHITE' },
  ['WHC']: { value: 'WHC', name: 'WHITE WESTINGHOUSE' },
  ['WHB']: { value: 'WHB', name: 'WHITEWESTING' },
  ['WHR']: { value: 'WHR', name: 'WHOLE HOME RELIANCE' },
  ['WIL']: { value: 'WIL', name: 'WILBILT' },
  ['WIB']: { value: 'WIB', name: 'WINDCREST' },
  ['WJM']: { value: 'WJM', name: 'WJM COMPUTERS' },
  ['WOL']: { value: 'WOL', name: 'WOLF' },
  ['WOI']: { value: 'WOI', name: 'WOODLAND INDUSTRIES' },
  ['WDS']: { value: 'WDS', name: 'WOODS' },
  ['WOR']: { value: 'WOR', name: 'WORKFILE SONY' },
  ['WOB']: { value: 'WOB', name: 'WORLDRICHMAN' },
  ['XIR']: { value: 'XIR', name: 'XIRCOM' },
  ['YAM']: { value: 'YAM', name: 'YAMAHA' },
  ['YH']: { value: 'YH', name: 'YAMAHA2' },
  ['YOR']: { value: 'YOR', name: 'YORK' },
  ['YOX']: { value: 'YOX', name: 'YORX' },
  ['ZEN']: { value: 'ZEN', name: 'ZENITH' },
  ['ZEP']: { value: 'ZEP', name: 'ZEPHYR' },
  ['ZER']: { value: 'ZER', name: 'ZERO' },
  ['ZOO']: { value: 'ZOO', name: 'ZOOM' },
  ['BLMB']: { value: 'BLMB', name: 'BLOOMBERG' },
  ['COMER']: { value: 'COMER', name: 'COMMERCIAL COOL' },
  ['GAG']: { value: 'GAG', name: 'GAGGENAU' },
};
