import React, { useState } from 'react';
import { Checklist, ChecklistStep, ChecklistBlueprint, ChecklistBlueprintStep, ChecklistStepType, Maybe } from '@equips/entities-schema';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Button from '../Buttons/Button';
import IconButton from '../Buttons/IconButton';
import Input from '../Form/Input';
import Label from '../Form/Label';
import { confirm } from '../Alerts/Prompt';
import { enumToOptions } from '../../../graphql/enums';
import SortableList from '../Sortable/SortableList';

type Step = {
  __typename?: string | null;
  label?: string | null;
  type?: ChecklistStepType | null;
  options?: Maybe<string>[] | null;
  completedAt?: number | null;
};

const DEFAULT_OPTIONS = ['True', 'False', 'Yes', 'No', 'Pass', 'Fail'];
const EMPTY_STEP = {
  label: '',
  type: ChecklistStepType.Checkbox,
};

function isChecklist(checklist: Checklist | ChecklistBlueprint): checklist is Checklist {
  return checklist['checklistId'];
}

function getDefaultSteps(checklist: Checklist | ChecklistBlueprint): Step[] {
  if (isChecklist(checklist)) {
    return checklist.metadata?.steps?.filter((step): step is ChecklistStep => !!step) || [EMPTY_STEP];
  } else {
    return checklist.metadata?.steps?.filter((step): step is ChecklistBlueprintStep => !!step) || [EMPTY_STEP];
  }
}

export default function ChecklistEditor({
  checklist = { __typename: 'ChecklistBlueprint' },
  onSave,
  disabled,
}: {
  checklist?: Checklist | ChecklistBlueprint;
  onSave: (checklist) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(checklist?.metadata?.name || '');
  const [steps, setSteps] = useState(getDefaultSteps(checklist));

  const addStep = () => {
    setSteps((steps) => [...steps, EMPTY_STEP]);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      addStep();
    }
  };

  const handleUpdate = (updatedStep, index) => {
    const updatedSteps = steps.map((step, i) => (i === index ? { ...step, ...updatedStep } : step));
    setSteps(updatedSteps);
  };

  const handleRemove = async (index) => {
    const step = steps[index];
    if (step.completedAt) {
      const confirmed = await confirm(t('checklistStepRemoveConfirmation'), {
        subtitle: t('checklistStepRemoveConfirmationHelper'),
      });

      if (confirmed) {
        setSteps((steps) => steps.filter((_step, i) => i !== index));
      }
    } else {
      setSteps((steps) => steps.filter((_step, i) => i !== index));
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSave({ ...checklist, metadata: { ...checklist.metadata, name, steps } });
      }}
      data-testid="ChecklistEditorForm"
    >
      <div className="bg-white p-4">
        <div>
          <Label id="name" label={t('name')} />
          <Input id="name" value={name} placeholder={t('name')} onChange={(event) => setName(event.target.value)} required />
        </div>
        <div className="my-2 flex justify-between">
          <Label id="steps" label={t('steps')} />
          <IconButton text={t('add')} type="button" Icon={Add} onClick={() => addStep()} />
        </div>
        <div>
          <SortableList
            values={steps}
            onChange={(steps) => setSteps(steps)}
            formatter={(step, index) => (
              <div className={`flex w-full gap-2 p-2 ${index % 2 === 0 ? 'bg-gray-300' : 'bg-gray-100'}`}>
                <div className="grow">
                  <Input
                    id="step"
                    className="h-14 rounded-lg"
                    value={step?.label || ''}
                    placeholder={t('step')}
                    onChange={(event) => handleUpdate({ label: event.target.value }, index)}
                    required
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                  />
                </div>
                <div className="w-64">
                  <Select
                    id="type"
                    className="w-full"
                    value={step?.type || ChecklistStepType.Checkbox}
                    onChange={(event) => handleUpdate({ type: event.target.value }, index)}
                  >
                    {enumToOptions('ChecklistStepType', ChecklistStepType).map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="w-[32rem]">
                  {(step?.type === ChecklistStepType.Single || step?.type === ChecklistStepType.Multiple) && (
                    <Autocomplete
                      multiple
                      freeSolo
                      disableCloseOnSelect
                      disableClearable
                      value={step?.options || []}
                      options={Array.from(new Set([...(step?.options || []), ...DEFAULT_OPTIONS]))}
                      onChange={(_, value, reason) => {
                        if (reason) handleUpdate({ options: value }, index);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <div key={option}>
                            <Chip
                              label={`${option ?? ''}`}
                              {...getTagProps({ index })}
                              className="mr-1 mt-1 bg-equipsLightBlue/25 text-xs"
                            />
                          </div>
                        ))
                      }
                      renderInput={(params) => <TextField className="bg-white" placeholder={t('select')} {...params} />}
                    />
                  )}
                </div>
                <div className="w-8">
                  {index !== 0 && (
                    <IconButton text={t('delete')} type="button" Icon={Close} onClick={() => handleRemove(index)} disabled={disabled} />
                  )}
                </div>
              </div>
            )}
          />
        </div>
        <div className="flex justify-end pt-4">
          <Button type="submit" disabled={disabled}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  );
}
